import React from "react";
import { bem, IcInfo16, TextWithIcon, Tooltip } from "../../ui-library";
import "./MapStyle.scss";
import classNames from "classnames";

const mapStyleBem = bem("map-style");

export type MapStyleProps = {
    id: string;
    title: string;
    imageSrc: string;
    className?: string;
    checked?: boolean;
    onClick?: { (): void };
    tooltipMessage?: string;
    enableReselection: boolean;
};

export const MapStyle = ({
    id,
    title,
    imageSrc,
    className,
    checked,
    onClick,
    tooltipMessage,
    enableReselection,
    ...rest
}: MapStyleProps) => (
    <div key={id} onClick={onClick} className={classNames(className, mapStyleBem.block())} {...rest}>
        <div
            className={classNames(mapStyleBem.element("image-wrapper"), {
                [mapStyleBem.element("image-wrapper--active")]: checked,
                [mapStyleBem.element("image-wrapper--hover--active")]: !enableReselection && checked
            })}
        >
            <img
                src={imageSrc}
                alt={title}
                className={classNames(mapStyleBem.element("image"), {
                    [mapStyleBem.element("image--active")]: checked,
                    [mapStyleBem.element("image--hover--active")]: !enableReselection && checked
                })}
            />
        </div>
        {/* The id field is coupled with CSS styling: */}
        <div className={mapStyleBem.element("message")}>
            <TextWithIcon
                id={"style-title"}
                text={title}
                clickable
                checked={checked}
                className={classNames(mapStyleBem.element("title"), {
                    [mapStyleBem.element("title--active")]: checked,
                    [mapStyleBem.element("title--hover--active")]: !enableReselection && checked
                })}
            />
            {tooltipMessage && <Tooltip icon={<IcInfo16 />} message={tooltipMessage} />}
        </div>
    </div>
);
