import React, { PropsWithChildren } from "react";
import { Link, LinkProps, Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import omit from "lodash/omit";

export const langParameter = "/:language";

const routeBasePart = "route";
const locationBasePart = "location";
const myItemsBasePart = "my-items";
const noPageBasePart = "404";
const settingsBasePart = "settings";
const labsBasePart = "labs";

export const baseURLPathParts = [
    routeBasePart,
    locationBasePart,
    myItemsBasePart,
    noPageBasePart,
    settingsBasePart,
    labsBasePart
];

export enum URL_PATH_MAP {
    // Home page
    INDEX = `/:language`,

    // Route pages
    ROUTE_PLAN = `/:language/route/plan`,
    ROUTE_PLAN_SETTINGS = `/:language/route/plan/settings`,
    ROUTE_PLAN_SETTINGS_FOCUS = "/:language/route/plan/settings/:focus?",
    ROUTE_PLAN_SETTINGS_TIME = "/:language/route/plan/settings/time",
    ROUTE_PLAN_SETTINGS_TYPE = "/:language/route/plan/settings/type",
    ROUTE_PLAN_SETTINGS_AVOID = "/:language/route/plan/settings/avoid",
    ROUTE_VIEW_BASE = `/:language/route/view`,
    ROUTE_VIEW = `/:language/route/view/:id`,
    ROUTE_EDIT_BASE = `/:language/route/edit`,
    ROUTE_EDIT_INFO_BASE = `/:language/route/edit/info`,
    ROUTE_EDIT_INFO = `/:language/route/edit/info/:id`,
    ROUTE_EDIT_LINE_BASE = `/:language/route/edit/line`,
    ROUTE_EDIT_LINE = `/:language/route/edit/line/:id`,
    ROUTE_NEW_BASE = `/:language/route/new`,
    ROUTE_NEW_INFO = `/:language/route/new/info`,
    ROUTE_SYNC_BASE = `/:language/route/sync`,
    ROUTE_SYNC = `/:language/route/sync/:id`,

    // Locations
    LOCATION_BASE = `/:language/location`,
    LOCATION_ADDR_COORDS_BASE = `/:language/location/addr`,
    LOCATION_ADDR_COORDS = `/:language/location/addr/:address/:coordinates`,
    LOCATION_ID_BASE = `/:language/location/id`,
    LOCATION_ID = `/:language/location/id/:id`,

    // Own items
    MY_ITEMS_BASE = `/:language/my-items`,
    MY_ITEMS_FOCUS = `/:language/my-items/:focus`,
    MY_ITEMS_PLACES = `/:language/my-items/places`,
    MY_ITEMS_OV2 = `/:language/my-items/ov2`,
    MY_ITEMS_ROUTES = `/:language/my-items/routes`,

    // 404
    PAGE_404 = `/:language/404`,

    // Settings
    SETTINGS = `/:language/settings`,

    // Lab toggles page
    LABS = `/:language/labs`
}

export const isBaseURLPart = (path: string) => baseURLPathParts.includes(path);

type BaseLinkProps = PropsWithChildren<Record<string, unknown>> & { pathname: URL_PATH_MAP };

const BaseLink = (props: BaseLinkProps) => {
    const { i18n } = useTranslation();
    const pathname = props.pathname.replace(langParameter, `/${i18n.language}`);

    return (
        <Link
            to={{
                pathname,
                search: useLocation().search
            }}
            {...omit(props, ["history", "match", "children", "pathname"])}
        >
            {props.children}
        </Link>
    );
};

type Props = PropsWithChildren<Record<string, unknown>> & Omit<LinkProps, "to">;

export const PlannerLink = (props: Props) => <BaseLink pathname={URL_PATH_MAP.ROUTE_PLAN} {...props} />;
export const HomeLink = (props: Props) => <BaseLink pathname={URL_PATH_MAP.INDEX} {...props} />;
export const PlannerSettingsLink = (props: Props) => (
    <BaseLink pathname={URL_PATH_MAP.ROUTE_PLAN_SETTINGS} {...props} />
);
export const PlannerSettingsTimeLink = (props: Props) => (
    <BaseLink pathname={URL_PATH_MAP.ROUTE_PLAN_SETTINGS_TIME} {...props} />
);
export const PlannerSettingsTypeLink = (props: Props) => (
    <BaseLink pathname={URL_PATH_MAP.ROUTE_PLAN_SETTINGS_TYPE} {...props} />
);
export const PlannerSettingsAvoidLink = (props: Props) => (
    <BaseLink pathname={URL_PATH_MAP.ROUTE_PLAN_SETTINGS_AVOID} {...props} />
);
export const MyItemsLink = (props: Props) => <BaseLink pathname={URL_PATH_MAP.MY_ITEMS_BASE} {...props} />;
export const MyPlacesLink = (props: Props) => <BaseLink pathname={URL_PATH_MAP.MY_ITEMS_PLACES} {...props} />;
export const MyPlacesOV2Link = (props: Props) => <BaseLink pathname={URL_PATH_MAP.MY_ITEMS_OV2} {...props} />;
export const MyRoutesLink = (props: Props) => <BaseLink pathname={URL_PATH_MAP.MY_ITEMS_ROUTES} {...props} />;
export const SettingsLink = (props: Props) => <BaseLink pathname={URL_PATH_MAP.SETTINGS} {...props} />;
export const LabsLink = (props: Props) => <BaseLink pathname={URL_PATH_MAP.LABS} {...props} />;

type RedirectRouteProps = { pathname: URL_PATH_MAP };

export const Redirection = (props: RedirectRouteProps) => {
    const { i18n } = useTranslation();
    const pathname = props.pathname.replace(langParameter, `/${i18n.language}`);

    return (
        <Redirect
            to={{
                pathname,
                search: useLocation().search
            }}
        />
    );
};
