import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { bem, Empty, IcClose24, InPanelMessage, TextArea, useQaId } from "../../ui-library";
import Modal from "bootstrap/js/dist/modal";

import { RootState, useAppDispatch } from "../../state/RootReducer";
import { actions as notificationActions } from "../../state/tree/notification/reducers";
import { submitSuggestion } from "../../state/tree/map-page/suggest-edit/thunks";
import { ReactComponent as IcForbidden40 } from "../../../resources/icons/ic-forbidden-40.svg";

import "./SuggestEditModal.scss";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { actions as suggestEditActions } from "../../state/tree/map-page/suggest-edit/reducers";

const editModal = bem("suggest-edit-modal");

const SuggestEditModalCmp = () => {
    let editForm;
    const dispatch = useAppDispatch();
    const editModalDivRef = useRef<HTMLDivElement>();
    const closeRef = useRef<HTMLButtonElement>();
    const editModalQa = useQaId("suggest-edit-modal");
    const [showError, setShowError] = useState(false);
    const { t } = useTranslation("SuggestEditModal");
    const isEditModalOpened = useSelector((state: RootState) => state.mapPage.suggestEdit.isEditModalOpened);

    useEffect(() => {
        if (isEditModalOpened) {
            const modalLogin = Modal.getOrCreateInstance(editModalDivRef.current);
            modalLogin.toggle();
        }
    }, [isEditModalOpened]);

    const handleSubmit = async (formValues, formApi) => {
        const response = await dispatch(submitSuggestion(formValues.edit_suggestion));
        if (response.payload === "success") {
            setShowError(false);
            formApi.reset();
            closeRef.current.click();
            dispatch(
                notificationActions.addGenericNotification({
                    heading: t("success_notification_title"),
                    message: t("success_notification_message"),
                    notificationType: "generic-message"
                })
            );
        } else {
            // show error in panel
            setShowError(true);
        }
    };

    const handleFormCancel = () => {
        setShowError(false);
        dispatch(suggestEditActions.setIsSuggestEditModalOpened(false));
        editForm.reset();
    };

    return (
        <div
            className={classNames(editModal.block(), "modal fade")}
            id="SuggestEditModal"
            ref={editModalDivRef}
            {...editModalQa.block()}
        >
            <div className="modal-dialog modal-dialog-centered" data-bs-focus={"true"}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" {...editModalQa.element("heading")}>
                            {t("suggest_an_edit")}
                        </h5>
                        <span
                            data-bs-dismiss="modal"
                            ref={closeRef}
                            {...editModalQa.element("close")}
                            onClick={handleFormCancel}
                        >
                            <IcClose24 className="btn-close" />
                        </span>
                    </div>

                    {showError && (
                        /* revise string */
                        <InPanelMessage
                            className={editModal.element("error")}
                            image={<IcForbidden40 />}
                            message={t("error_msg")}
                            {...editModalQa.element("error")}
                        />
                    )}

                    <Form
                        onSubmit={(formValues, formApi) => handleSubmit(formValues, formApi)}
                        render={({ handleSubmit, form, submitting, pristine }) => {
                            editForm = form;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <Field name="edit_suggestion">
                                                {(props) => (
                                                    <TextArea
                                                        name={props.input.name}
                                                        value={props.input.value}
                                                        onChange={props.input.onChange}
                                                        id="edit_suggestion"
                                                        label={t("suggest_an_edit")}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={submitting || pristine}
                                            {...editModalQa.element("submit")}
                                        >
                                            {t("submit")}
                                        </button>
                                    </div>
                                </form>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const SuggestEditModal = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <SuggestEditModalCmp />
    </IsomorphicSuspense>
);

export default SuggestEditModal;
