import React, { ReactNode } from "react";
import { UnitsType } from "@anw/gor-sdk";
import { Empty, IcPositionRecentre24, ListsItem, qaId } from "../../ui-library";
import { useTranslation } from "react-i18next";

import { TTMLocation } from "../../utils/locationTypes";
import { buildNgsHeading } from "../../utils/location";
import { AutocompleteSegment } from "../../state/tree/map-page/search/autocompleteAPITypes";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

type BaseSearchItemParams = Partial<SearchWithLocationParams> & {
    text?: string;
    subTitle?: string;
    unitType?: UnitsType;
    userLocation?: [number, number];
    icon?: ReactNode;
    segmentType?: string;
    mode?: string;
    plannerFocusedInputIndex?: number;
    dataQaId?: string;
};

export const BaseSearchItem = (props: BaseSearchItemParams) => {
    const searchItemQa = qaId(props.dataQaId || "search-item");
    const { result, index } = props;
    const heading = result && buildNgsHeading(result, { addressSubtitle: true });

    return (
        <div className={"list-group-item-wrapper"} {...searchItemQa.block()}>
            <button
                className={`list-group-item list-group-item-action item-type-${props.segmentType || "regular"}`}
                onClick={() => props.onClick?.(result, index)}
                onMouseOver={() => props.onHover?.(result, index)}
                onMouseLeave={props.onMouseLeave}
                {...searchItemQa.element("action-trigger")}
            >
                <ListsItem
                    title={props.text || (result && heading.title)}
                    subTitle={props.subTitle || (result && heading.subtitle)}
                    iconLeft={props.icon}
                    {...searchItemQa.element("content")}
                />
            </button>
        </div>
    );
};

export type SearchWithLocationParams = {
    result: TTMLocation;
    index: number;
    unitType?: UnitsType;
    userLocation?: [number, number];
    onClick?: (res: TTMLocation | AutocompleteSegment, index: number) => void;
    onHover?: (res: TTMLocation, index: number) => void;
    onMouseLeave?: () => void;
    dataQaId?: string;
    mode?: string;
    plannerFocusedInputIndex?: number;
    isSaved?: boolean;
    isRecentSearch?: boolean;
};

type CurrentLocationParams = {
    onHandleCurrentLocation: () => void;
    dataQaId?: string;
};

const CurrentLocationCmp = (props: CurrentLocationParams) => {
    const { t } = useTranslation("CurrentLocation");

    return (
        <BaseSearchItem
            text={t("CurrentLocation:search_text")}
            onClick={props.onHandleCurrentLocation}
            icon={<IcPositionRecentre24 />}
            dataQaId={props.dataQaId}
        />
    );
};

export const CurrentLocation = (props: CurrentLocationParams) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <CurrentLocationCmp {...props} />
    </IsomorphicSuspense>
);

type HeadingParams = {
    text: string;
};
export const Heading = (props: HeadingParams) => {
    return <div className="list-group-item heading">{props.text}</div>;
};
