import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { bem, Empty, qaId } from "../../ui-library";

import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import Header from "../elements/Header";
import AnimationWrapper from "../elements/AnimationWrapper";
import { Redirection, URL_PATH_MAP } from "../elements/Links";
import RecoveryErrorBoundary from "../elements/errors/RecoveryErrorBoundary";
import Page404 from "../404-page/404";
import MapOptionShortcuts from "./MapOptionShortcuts";

import { selectPlannerCollapsed } from "../../state/tree/map-page/header/selectors";
import { useAppDispatch } from "../../state/RootReducer";
import { clearCalculatedRouteAndCriteria } from "../../state/tree/map-page/planner/thunks";
import { clearSelectedLocation } from "../../state/tree/map-page/location/thunks";
import { clearSearch } from "../../state/tree/map-page/search/thunks";
import { actions as locationActions } from "../../state/tree/map-page/location/reducers";
import { updateMyPlaces } from "../../state/tree/map-page/my-items/thunks";
import { actions as myItemsActions } from "../../state/tree/map-page/my-items/reducers";
import { navigateToMyRoutes, navigateToRoutePlan } from "../../state/tree/navigation/thunks";

import "./PanelsContainer.scss";

const LazySearch = React.lazy(() => import("./Search"));
const LazyRoutePlan = React.lazy(() => import("../route-plan/RoutePlan"));
const LazyLocationDetails = React.lazy(() => import("./LocationDetails"));
const LazyRouteEditLine = React.lazy(() => import("../route-edit-line/RouteEditLine"));
const LazyMyItems = React.lazy(() => import("./MyItems"));
const LazyRouteView = React.lazy(() => import("../route-view/RouteView"));
const LazyRoutePlanSettings = React.lazy(() => import("../route-plan-settings/RoutePlanSettings"));
const LazyRouteEditInfo = React.lazy(() => import("../route-edit-info/RouteEditInfo"));
const LazyRouteSync = React.lazy(() => import("../route-sync/RouteSync"));
const LazyRouteNewInfo = React.lazy(() => import("../route-new-info/RouteNewInfo"));
const LazyLabs = React.lazy(() => import("../menu/Labs"));
const LazySettings = React.lazy(() => import("../menu/Settings"));
const LazyOV2 = React.lazy(() => import("./OV2"));

const panelsContainerBem = bem("panels-container");
const panelTemplateBem = bem("panel-template");
const panelsContainerQa = qaId("panels-container");

function AnimatedSearchWrapper(
    props: PropsWithChildren<{
        match: unknown;
        name: string;
        boundaryName: string;
        resetLogic: () => void;
    }>
) {
    return (
        <AnimationWrapper
            show={props.match != null}
            animationType="move-in-left-out-right"
            wrapperClassNameModifier={["search-route", props.name]}
            component={
                props.boundaryName ? (
                    <RecoveryErrorBoundary resetLogic={props.resetLogic} boundaryName={props.boundaryName}>
                        {props.children}
                    </RecoveryErrorBoundary>
                ) : (
                    <>{props.children}</>
                )
            }
        />
    );
}

const PanelsContainer = () => {
    const plannerCollapsed = useSelector(selectPlannerCollapsed);
    const dispatch = useAppDispatch();

    return (
        <div className={panelsContainerBem.block()} {...panelsContainerQa.block()}>
            <IsomorphicSuspense fallback={<Empty />}>
                <div
                    {...panelsContainerQa.element("top")}
                    className={classNames(
                        panelsContainerBem.element("panel"),
                        panelTemplateBem.block(),
                        panelTemplateBem.modifier("top"),
                        panelTemplateBem.modifier("search")
                    )}
                >
                    {/*Header*/}
                    <Header />

                    {/*Search*/}
                    <Route
                        exact={true}
                        path={[
                            URL_PATH_MAP.LOCATION_ID,
                            URL_PATH_MAP.LOCATION_ADDR_COORDS,
                            URL_PATH_MAP.PAGE_404,
                            URL_PATH_MAP.INDEX
                        ]}
                    >
                        {({ match }) => (
                            <AnimationWrapper
                                show={match != null}
                                wrapperClassNameModifier="search-routes"
                                component={
                                    <>
                                        <Route exact={true} path={URL_PATH_MAP.LOCATION_ID}>
                                            <AnimatedSearchWrapper
                                                match={match}
                                                name={"location-details"}
                                                boundaryName={"LocationDetails"}
                                                resetLogic={() => {
                                                    dispatch(clearSelectedLocation());
                                                }}
                                            >
                                                <IsomorphicSuspense fallback={<Empty />}>
                                                    <LazyLocationDetails />
                                                </IsomorphicSuspense>
                                            </AnimatedSearchWrapper>
                                        </Route>
                                        <Route exact={true} path={URL_PATH_MAP.LOCATION_ADDR_COORDS}>
                                            <AnimatedSearchWrapper
                                                match={match}
                                                name="location-details"
                                                boundaryName="LocationDetails"
                                                resetLogic={() => {
                                                    // need to do it first because of auto getting location based on url
                                                    dispatch(locationActions.setHasBoundaryError(true));
                                                    dispatch(clearSelectedLocation());
                                                }}
                                            >
                                                <IsomorphicSuspense fallback={<Empty />}>
                                                    <LazyLocationDetails />
                                                </IsomorphicSuspense>
                                            </AnimatedSearchWrapper>
                                        </Route>
                                        <Route exact={true} path={URL_PATH_MAP.PAGE_404}>
                                            <AnimatedSearchWrapper
                                                match={match}
                                                name="page-404"
                                                boundaryName="Page404"
                                                resetLogic={() => {
                                                    // need to do it first because of auto getting location based on url
                                                    dispatch(locationActions.setHasBoundaryError(true));
                                                    dispatch(clearSelectedLocation());
                                                }}
                                            >
                                                <Page404 />
                                            </AnimatedSearchWrapper>
                                        </Route>
                                        <Route exact={true} path={URL_PATH_MAP.INDEX}>
                                            <AnimatedSearchWrapper
                                                match={match}
                                                name="search"
                                                boundaryName="Search"
                                                resetLogic={() => {
                                                    dispatch(clearSearch());
                                                }}
                                            >
                                                <IsomorphicSuspense fallback={<Empty />}>
                                                    <LazySearch />
                                                </IsomorphicSuspense>
                                            </AnimatedSearchWrapper>
                                        </Route>
                                    </>
                                }
                            />
                        )}
                    </Route>
                </div>

                {/*Map Option Shortcuts - e.g. shortcut to enable traffic layer*/}
                <Route exact={true} path={URL_PATH_MAP.INDEX}>
                    <MapOptionShortcuts />
                </Route>

                {/*My Items*/}

                <Switch>
                    <Route exact path={URL_PATH_MAP.MY_ITEMS_OV2}>
                        {({ match }) => (
                            <div
                                {...panelsContainerQa.element("overlay")}
                                className={classNames(
                                    panelsContainerBem.element("panel"),
                                    panelTemplateBem.block(),
                                    panelTemplateBem.modifier("overlay"),
                                    panelTemplateBem.modifier("items-ov2")
                                )}
                            >
                                <AnimationWrapper
                                    show={match != null}
                                    animationType={"fade-in-out"}
                                    wrapperClassNameModifier="items-ov2"
                                    component={
                                        match != null ? (
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyOV2 />
                                            </IsomorphicSuspense>
                                        ) : (
                                            <Empty />
                                        )
                                    }
                                />
                            </div>
                        )}
                    </Route>
                    <Route exact path={URL_PATH_MAP.MY_ITEMS_BASE}>
                        <Redirection pathname={URL_PATH_MAP.MY_ITEMS_PLACES} />
                    </Route>
                    <Route exact path={URL_PATH_MAP.MY_ITEMS_FOCUS}>
                        {({ match }) => (
                            <div
                                className={classNames(
                                    panelsContainerBem.element("panel"),
                                    panelTemplateBem.block(),
                                    panelTemplateBem.modifier("support-small"),
                                    panelTemplateBem.modifier("my-items")
                                )}
                            >
                                <AnimationWrapper
                                    show={match != null}
                                    wrapperClassNameModifier="my-items"
                                    animationType={"fade-in-out"}
                                    component={
                                        <RecoveryErrorBoundary
                                            boundaryName="MyItems"
                                            resetLogic={() => {
                                                dispatch(updateMyPlaces([]));
                                                dispatch(myItemsActions.setOV2Files([]));
                                                dispatch(myItemsActions.setUserItineraries([]));
                                            }}
                                        >
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyMyItems />
                                            </IsomorphicSuspense>
                                        </RecoveryErrorBoundary>
                                    }
                                />
                            </div>
                        )}
                    </Route>
                </Switch>

                {/*Route Planner*/}
                <Route exact={true} path={URL_PATH_MAP.ROUTE_PLAN}>
                    {({ match }) => (
                        <div
                            {...panelsContainerQa.element("bottom")}
                            className={classNames(
                                panelsContainerBem.element("panel"),
                                panelTemplateBem.block(),
                                panelTemplateBem.modifier("bottom"),
                                panelTemplateBem.modifier("planner")
                            )}
                        >
                            <AnimationWrapper
                                show={match != null && !plannerCollapsed}
                                wrapperClassNameModifier="planner"
                                component={
                                    match != null ? (
                                        <RecoveryErrorBoundary
                                            boundaryName="RoutePlan"
                                            resetLogic={() => {
                                                dispatch(clearCalculatedRouteAndCriteria());
                                            }}
                                        >
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyRoutePlan />
                                            </IsomorphicSuspense>
                                        </RecoveryErrorBoundary>
                                    ) : (
                                        <Empty />
                                    )
                                }
                            />
                        </div>
                    )}
                </Route>

                {/*Route Planner Settings*/}
                <Route exact path={URL_PATH_MAP.ROUTE_PLAN_SETTINGS_FOCUS} strict={true}>
                    {({ match }) => (
                        <div
                            {...panelsContainerQa.element("overlay")}
                            className={classNames(
                                panelsContainerBem.element("panel"),
                                panelTemplateBem.block(),
                                panelTemplateBem.modifier("overlay"),
                                panelTemplateBem.modifier("planner-settings")
                            )}
                        >
                            <AnimationWrapper
                                show={match != null}
                                animationType={"fade-in-out"}
                                wrapperClassNameModifier="planner-settings"
                                component={
                                    match != null ? (
                                        <IsomorphicSuspense fallback={<Empty />}>
                                            <LazyRoutePlanSettings />
                                        </IsomorphicSuspense>
                                    ) : (
                                        <Empty />
                                    )
                                }
                            />
                        </div>
                    )}
                </Route>

                {/*New Route*/}
                <Route exact path={URL_PATH_MAP.ROUTE_NEW_INFO}>
                    {({ match }) => (
                        <div
                            className={classNames(
                                panelsContainerBem.element("panel"),
                                panelTemplateBem.block(),
                                panelTemplateBem.modifier("overlay"),
                                panelTemplateBem.modifier("save-route")
                            )}
                        >
                            <AnimationWrapper
                                show={match != null}
                                wrapperClassNameModifier="save-route"
                                component={
                                    match != null ? (
                                        <RecoveryErrorBoundary
                                            boundaryName="RouteNewInfo"
                                            resetLogic={() => {
                                                dispatch(myItemsActions.setSelectedItinerary(null));
                                                dispatch(myItemsActions.setSelectedItineraryOrigin(null));
                                                dispatch(navigateToRoutePlan());
                                            }}
                                        >
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyRouteNewInfo />
                                            </IsomorphicSuspense>
                                        </RecoveryErrorBoundary>
                                    ) : (
                                        <Empty />
                                    )
                                }
                            />
                        </div>
                    )}
                </Route>

                {/*Edit route details*/}
                <Route exact path={URL_PATH_MAP.ROUTE_EDIT_INFO}>
                    {({ match }) => (
                        <div
                            className={classNames(
                                panelsContainerBem.element("panel"),
                                panelTemplateBem.block(),
                                panelTemplateBem.modifier("overlay"),
                                panelTemplateBem.modifier("save-route")
                            )}
                        >
                            <AnimationWrapper
                                show={match != null}
                                wrapperClassNameModifier="save-route"
                                component={
                                    match != null ? (
                                        <RecoveryErrorBoundary
                                            boundaryName="RouteEditInfo"
                                            resetLogic={() => {
                                                dispatch(myItemsActions.setSelectedItinerary(null));
                                                dispatch(myItemsActions.setSelectedItineraryOrigin(null));
                                                dispatch(navigateToMyRoutes());
                                            }}
                                        >
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyRouteEditInfo itineraryId={match.params.id} />
                                            </IsomorphicSuspense>
                                        </RecoveryErrorBoundary>
                                    ) : (
                                        <Empty />
                                    )
                                }
                            />
                        </div>
                    )}
                </Route>
                <Route exact path={URL_PATH_MAP.ROUTE_EDIT_LINE}>
                    {({ match }) => (
                        <div
                            className={classNames(
                                panelsContainerBem.element("panel"),
                                panelTemplateBem.block(),
                                panelTemplateBem.modifier("overlay"),
                                panelTemplateBem.modifier("save-route")
                            )}
                        >
                            <AnimationWrapper
                                show={match != null}
                                wrapperClassNameModifier="save-route"
                                component={
                                    match != null ? (
                                        <RecoveryErrorBoundary
                                            boundaryName="RouteEditLine"
                                            resetLogic={() => {
                                                dispatch(myItemsActions.setSelectedItinerary(null));
                                                dispatch(myItemsActions.setSelectedItineraryOrigin(null));
                                                dispatch(navigateToMyRoutes());
                                            }}
                                        >
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyRouteEditLine itineraryId={match.params.id} />
                                            </IsomorphicSuspense>
                                        </RecoveryErrorBoundary>
                                    ) : (
                                        <Empty />
                                    )
                                }
                            />
                        </div>
                    )}
                </Route>

                {/*Sync Route*/}
                <Route exact path={URL_PATH_MAP.ROUTE_SYNC}>
                    {({ match }) => (
                        <div
                            className={classNames(
                                panelsContainerBem.element("panel"),
                                panelTemplateBem.block(),
                                panelTemplateBem.modifier("overlay"),
                                panelTemplateBem.modifier("route-sync")
                            )}
                        >
                            <AnimationWrapper
                                show={match != null}
                                wrapperClassNameModifier="route-sync"
                                component={
                                    match != null ? (
                                        <RecoveryErrorBoundary
                                            boundaryName="RouteSync"
                                            resetLogic={() => {
                                                dispatch(myItemsActions.setSelectedItinerary(null));
                                                dispatch(myItemsActions.setSelectedItineraryOrigin(null));
                                                dispatch(navigateToMyRoutes());
                                            }}
                                        >
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyRouteSync />
                                            </IsomorphicSuspense>
                                        </RecoveryErrorBoundary>
                                    ) : (
                                        <Empty />
                                    )
                                }
                            />
                        </div>
                    )}
                </Route>

                {/*Route preview*/}
                <Route exact path={URL_PATH_MAP.ROUTE_VIEW}>
                    {({ match }) => (
                        <div
                            className={classNames(
                                panelsContainerBem.element("panel"),
                                panelTemplateBem.block(),
                                panelTemplateBem.modifier("overlay"),
                                panelTemplateBem.modifier("route-view")
                            )}
                        >
                            <AnimationWrapper
                                show={match != null}
                                wrapperClassNameModifier="route-view"
                                component={
                                    match != null ? (
                                        <RecoveryErrorBoundary
                                            boundaryName="RouteView"
                                            resetLogic={() => {
                                                dispatch(myItemsActions.setSelectedItinerary(null));
                                                dispatch(myItemsActions.setSelectedItineraryOrigin(null));
                                                dispatch(navigateToMyRoutes());
                                            }}
                                        >
                                            <IsomorphicSuspense fallback={<Empty />}>
                                                <LazyRouteView />
                                            </IsomorphicSuspense>
                                        </RecoveryErrorBoundary>
                                    ) : (
                                        <Empty />
                                    )
                                }
                            />
                        </div>
                    )}
                </Route>

                {/*Settings*/}
                <div
                    className={classNames(
                        panelsContainerBem.element("panel"),
                        panelTemplateBem.block(),
                        panelTemplateBem.modifier("support-small"),
                        panelTemplateBem.modifier("settings")
                    )}
                >
                    <Route key={URL_PATH_MAP.SETTINGS} exact path={URL_PATH_MAP.SETTINGS}>
                        <IsomorphicSuspense fallback={<Empty />}>
                            <LazySettings />
                        </IsomorphicSuspense>
                    </Route>
                </div>

                {/*Labs*/}
                <div
                    className={classNames(
                        panelsContainerBem.element("panel"),
                        panelTemplateBem.block(),
                        panelTemplateBem.modifier("support-small"),
                        panelTemplateBem.modifier("labs")
                    )}
                >
                    <Route key={URL_PATH_MAP.LABS} exact path={URL_PATH_MAP.LABS}>
                        <IsomorphicSuspense fallback={<Empty />}>
                            <LazyLabs />
                        </IsomorphicSuspense>
                    </Route>
                </div>
            </IsomorphicSuspense>
        </div>
    );
};

export default PanelsContainer;
