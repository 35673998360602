import React, { MouseEventHandler, ReactNode } from "react";
import classNames from "classnames";
import { qaId, bem, Empty } from "../../ui-library";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    selectAnyTrafficToggled,
    selectIsMyPlacesToggled,
    selectIsPOILayerChanged,
    selectMapOptionsDisplayedFromTrafficShortcut,
    selectMapOptionsDisplayedFromRoadTripShortcut,
    selectRoadTripsToggled,
    selectScenicSegmentsToggled,
    selectRiderRoutesToggled
} from "../../state/tree/map-page/map-controls/selectors";
import { selectAuthenticated } from "../../state/tree/authentication/selectors";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import "./MapOptionsToggle.scss";

const mapOptionsToggle = bem("map-options-toggle");
const mapControlsQa = qaId("map-controls");

type ToggleProps = {
    icon: ReactNode;
    onClick?: MouseEventHandler;
    toggleOn?: boolean;
};

const MapOptionsToggleCmp = ({ icon, onClick, toggleOn }: ToggleProps) => {
    const { t } = useTranslation("MapOptions");
    const authenticated = useSelector(selectAuthenticated);
    const anyTrafficToggled = useSelector(selectAnyTrafficToggled);
    const roadTripsToggled = useSelector(selectRoadTripsToggled);
    const riderRoutesToggled = useSelector(selectRiderRoutesToggled);
    const scenicSegmentsToggled = useSelector(selectScenicSegmentsToggled);
    const isPOILayerChanged = useSelector(selectIsPOILayerChanged);
    const isMyPlacesToggled = useSelector(selectIsMyPlacesToggled);
    // make sure feature toggle is on, and user logged in when determining my places change
    const showDot =
        anyTrafficToggled ||
        isPOILayerChanged ||
        (authenticated && !isMyPlacesToggled) ||
        roadTripsToggled ||
        riderRoutesToggled ||
        scenicSegmentsToggled;
    const mapOptionsDisplayedFromTrafficShortcut = useSelector(selectMapOptionsDisplayedFromTrafficShortcut);
    const mapOptionsDisplayedFromRoadTripShortcut = useSelector(selectMapOptionsDisplayedFromRoadTripShortcut);

    return (
        <div
            className={classNames(mapOptionsToggle.block(), {
                [mapOptionsToggle.modifier("on")]: toggleOn,
                [mapOptionsToggle.modifier("notification")]: showDot,
                [mapOptionsToggle.modifier("emphasis")]: mapOptionsDisplayedFromTrafficShortcut,
                [mapOptionsToggle.modifier("emphasis")]: mapOptionsDisplayedFromRoadTripShortcut
            })}
        >
            <button
                className={mapOptionsToggle.element("toggle")}
                aria-label="toggle map options"
                onClick={onClick}
                title={t("title")}
                {...mapControlsQa.element("toggle")}
            >
                {icon}
            </button>
        </div>
    );
};

const MapOptionsToggle = (props: ToggleProps) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <MapOptionsToggleCmp {...props} />
    </IsomorphicSuspense>
);

export default MapOptionsToggle;
