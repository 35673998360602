import React, { FC } from "react";
import { Store } from "redux";
import { Provider, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Main from "./Main";
import BrowserHistory from "../../state/BrowserHistory";
import { selectAnalyticsEnvKey } from "../../state/tree/global-configuration/selectors";
import { isPrerendering, isSilent, isSSR } from "../../utils/agent";
import { RedirectToLocalizedURLPath } from "../../classes/localization/RedirectToLocalizedURLPath";
import GlobalErrorHandler from "../../classes/GlobalErrorHandler";
import { PORTAL_CONTAINER_IDS, PortalContainer } from "../elements/Portal";
import CookiePanel from "../cookie-panel/CookiePanel";

export interface AppProps {
    store: Store;
}

const App: FC = () => {
    const analyticsEnvKey = useSelector(selectAnalyticsEnvKey);
    const {
        i18n: { language }
    } = useTranslation();

    return (
        <>
            <Helmet>
                <html lang={language} />
                {!isSilent() && !isSSR() && (
                    <script
                        defer={true}
                        nonce={"tealium"}
                        src={`https://tags.tiqcdn.com/utag/tomtom/tomtommaps/${analyticsEnvKey}/utag.js`}
                    />
                )}
            </Helmet>
            <RedirectToLocalizedURLPath />
            <Main />
            <PortalContainer id={PORTAL_CONTAINER_IDS.PORTAL_CONTAINER} />
            {!isPrerendering() && <CookiePanel />}
        </>
    );
};

const Wrapper: FC<AppProps> = (props) => (
    <GlobalErrorHandler>
        <Provider store={props.store}>
            <ConnectedRouter history={BrowserHistory.Instance}>
                <App />
            </ConnectedRouter>
        </Provider>
    </GlobalErrorHandler>
);

export default Wrapper;
