import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { qaId } from "../../ui-library";
import { shared } from "@anw/gor-sdk";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import i18next from "i18next";
import "bootstrap";

import { isPrerendering } from "../../utils/agent";
import PanelsContainer from "../map-page/PanelsContainer";
import MapOptionsToggle from "../elements/MapOptionsToggle";
import { actions } from "../../state/tree/map-page/map-controls/reducers";
import MapOptionsPanel from "../map-page/MapOptionsPanel";
import { AppDispatchProp, RootState } from "../../state/RootReducer";
import { actions as userActions } from "../../state/tree/user/reducers";
import { defaultGlobalConfiguration } from "../../state/tree/global-configuration/reducers";
import SearchAreaButton from "../elements/SearchAreaButton";
import LoginModal from "../login/LoginModal";
import SalesModal from "../salesModal/SalesModal";
import SuggestEditModal from "../map-page/SuggestEditModal";
import { checkAuthentication } from "../../state/tree/authentication/thunks";
import PageNotifications from "../notifications/PageNotifications";
import LoginComponent from "../elements/LoginComponent";
import FavouriteEditModal from "../my-items/FavouriteEditModal";
import MapComponent from "../map/MapComponent";
import { PORTAL_CONTAINER_IDS, PortalContainer } from "../elements/Portal";
import { updateUserLocation } from "../../state/tree/user/thunks";
import { actions as applicationActions, calculateForegroundFromPath } from "../../state/tree/application/reducers";
import BrowserHistory from "../../state/BrowserHistory";
import { URL_PATH_MAP } from "../elements/Links";
import { RouteTimeline } from "../planner/RouteTimeline";
import { selectFeatureConfigs } from "../../state/tree/global-configuration/selectors";
import Menu from "../menu/Menu";

import { ReactComponent as IcControlsToggle } from "../../../resources/icons/controls-toggle.svg";

import "../../../sass/main.scss";
import { configure } from "../../services/ngs/ngsClient";

const mapStateToProps = (state: RootState) => ({
    pathname: state.router.location.pathname,
    mapOptionsDisplayed: state.mapPage.mapControls.mapOptionsDisplayed.displayed,
    gorConfig: state.globalConfiguration.gor,
    ngsConfig: state.globalConfiguration.ngs,
    isMenuOpen: state.application.isMenuOpen,
    placeBeingEdited: state.mapPage.myItems.placeBeingEdited,
    routeTimeline: selectFeatureConfigs(state).routeTimeline
});

type Props = AppDispatchProp & ReturnType<typeof mapStateToProps>;

const pageContainerQa = qaId("page-container");

class Main extends Component<Props> {
    constructor(props) {
        super(props);

        this.props.dispatch(applicationActions.updateForeground(calculateForegroundFromPath(this.props.pathname)));
    }

    componentDidMount() {
        const baseGORConfig = this.props.gorConfig || defaultGlobalConfiguration.gor;

        shared.create({
            apiConfig: {
                defaultApiConfig: {
                    headers: {
                        ...baseGORConfig.defaultHeaders,
                        // We make sure to inject this TTM version into the GOR config (probably not easy to determine in runtime config):
                        GOR_CLIENT_APP_VERSION: APP_VERSION
                    },
                    baseURL: baseGORConfig.baseURL,
                    locale: i18next.language || baseGORConfig.locale,
                    // set to true to get details of all request failures
                    debugging: false
                }
            }
        });
        configure({
            apiKey: this.props.ngsConfig.apiKey,
            baseURL: this.props.ngsConfig.baseURL
        })

        BrowserHistory.Instance.listen((historyLocation) => {
            if (historyLocation.pathname) {
                // monitoring the url/history, so we know in which state is application to properly handle foreground/background effects
                this.props.dispatch(
                    applicationActions.updateForeground(calculateForegroundFromPath(historyLocation.pathname))
                );
            }
        });

        this.props.dispatch(checkAuthentication());

        if (navigator.permissions) {
            navigator.permissions
                .query({
                    name: "geolocation"
                })
                .then((permission) => {
                    // is geolocation granted?
                    if (permission.state === "granted") {
                        // user allowed it so we set the state and will trigger get current location later in code
                        this.props.dispatch(userActions.setGeolocationAllowed(true));
                    } else {
                        // the user did not allow sharing of their location, so we ensure to clear it from state and storage:
                        this.props.dispatch(updateUserLocation(null));
                    }
                });
        }
    }

    handleToggleClick = () => {
        this.props.dispatch(actions.toggleMapOptionsDisplay(!this.props.mapOptionsDisplayed));
    };

    render() {
        const { mapOptionsDisplayed } = this.props;

        return (
            <PortalContainer
                id={PORTAL_CONTAINER_IDS.PAGE_CONTAINER}
                {...pageContainerQa.block()}
                className="page-container"
                role="main"
            >
                <div className={classNames("page-map", { "page-map--with-controls": mapOptionsDisplayed })}>
                    {!isPrerendering() && <MapComponent />}
                    <SearchAreaButton />
                    <PanelsContainer />
                    <MapOptionsToggle
                        icon={<IcControlsToggle />}
                        toggleOn={mapOptionsDisplayed}
                        onClick={this.handleToggleClick}
                    />
                    <CSSTransition in={mapOptionsDisplayed} timeout={300} unmountOnExit={true} mountOnEnter={true}>
                        <MapOptionsPanel />
                    </CSSTransition>
                </div>
                <SalesModal />
                <LoginModal />
                <SuggestEditModal />
                <Menu open={this.props.isMenuOpen} />
                <PageNotifications />
                <LoginComponent />
                {this.props.placeBeingEdited && <FavouriteEditModal />}

                {/* Route Timeline */}
                <Route
                    exact
                    path={[
                        URL_PATH_MAP.ROUTE_PLAN,
                        URL_PATH_MAP.ROUTE_PLAN_SETTINGS,
                        URL_PATH_MAP.ROUTE_EDIT_LINE,
                        URL_PATH_MAP.ROUTE_VIEW
                    ]}
                >
                    {this.props.routeTimeline && <RouteTimeline />}
                </Route>
            </PortalContainer>
        );
    }
}

export default connect(mapStateToProps)(Main);
