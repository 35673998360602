import React, { ChangeEvent, useState } from "react";
import { ListsItemWithCheckbox } from "../../ui-library";
import { cookiePanelBem, MANDATORY_KEYS } from "./CookiePanel";
import qaId from "../../ui-library/utils/qa-id";

const cookiePanelQa = qaId("cookie-panel");
type CookieOption = {
    value: string;
    label: string;
    disabled?: boolean;
};

type CookieOptionInputsProps = {
    options: CookieOption[];
    onChange?: (selectedOptions: string[]) => void;
};

const CookieOptionInputs = (props: CookieOptionInputsProps) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>(MANDATORY_KEYS);
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        const updatedSelectedOptions = checked
            ? [...selectedOptions, value]
            : selectedOptions.filter((option) => option !== value);
        setSelectedOptions(updatedSelectedOptions);

        if (props.onChange) {
            props.onChange(updatedSelectedOptions);
        }
    };

    return (
        <>
            {props.options.map((option) => (
                <ListsItemWithCheckbox
                    key={option.value}
                    title={option.label}
                    disabled={option.disabled}
                    position="left"
                    onChange={handleCheckboxChange}
                    parentQaId={cookiePanelQa.withModifier(option.value)}
                    className={cookiePanelBem.element("cookie-option")}
                    checked={selectedOptions.includes(option.value)}
                    value={option.value}
                />
            ))}
        </>
    );
};

export default CookieOptionInputs;
