import React from "react";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { bem, Empty, qaId } from "../../ui-library";
import { useTranslation } from "react-i18next";

import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import placeholderImage from "../../../resources/images/carousel_placeholder.png";

const poiImage = bem("poi-image");
const carouselQa = qaId("carousel");

const mapToDisplayedImages = (images: string[]) =>
    images.length ? [images[0]].concat(new Array(images.length - 1).fill(placeholderImage)) : [];

type Props = {
    images: string[];
    className: string;
};

const ImageCarouselCmp = (props: Props) => {
    const id = uniqueId("poi-img-carousel");
    const [loadOtherImages, setLoadOtherImages] = React.useState(false);
    const [displayedImages, setDisplayedImages] = React.useState([] as string[]);
    const { t } = useTranslation("ImageCarousel");

    React.useEffect(() => {
        if (loadOtherImages) {
            setDisplayedImages(props.images);
        } else {
            setDisplayedImages(mapToDisplayedImages(props.images));
        }
    }, [loadOtherImages, props.images]);

    const handleHoverControls = () => {
        if (!loadOtherImages) {
            setLoadOtherImages(true);
        }
    };

    return (
        <div
            id={id}
            className={classNames("carousel slide", poiImage.block(), props.className)}
            data-bs-ride="carousel"
            {...carouselQa.block()}
        >
            <div className="carousel-inner">
                {displayedImages.map((photo, i) => (
                    <div
                        key={photo}
                        className={classNames("carousel-item", poiImage.element("img-container"), {
                            active: i === 0
                        })}
                        {...carouselQa.element("image")}
                    >
                        <img src={photo} className="d-block" alt="photo" />
                    </div>
                ))}
            </div>
            {displayedImages.length > 1 && (
                <>
                    <a
                        className="carousel-control-prev"
                        onMouseEnter={handleHoverControls}
                        href={`#${id}`}
                        role="button"
                        data-bs-slide="prev"
                        {...carouselQa.element("previous")}
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">{t("ImageCarousel:previous")}</span>
                    </a>
                    <a
                        className="carousel-control-next"
                        onMouseEnter={handleHoverControls}
                        href={`#${id}`}
                        role="button"
                        data-bs-slide="next"
                        {...carouselQa.element("next")}
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">{t("ImageCarousel:next")}</span>
                    </a>
                </>
            )}
        </div>
    );
};

const ImageCarousel = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <ImageCarouselCmp {...props} />
    </IsomorphicSuspense>
);

export default ImageCarousel;
