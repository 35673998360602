import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import classNames from "classnames";
import { bem, ButtonContainer, ButtonPrimary, Empty, IcEdit24, IconSelect, IcShare16 } from "../../ui-library";
import { ReactComponent as IcShareFb24 } from "../../../resources/icons/ic-share-facebook.svg";
import { ReactComponent as IcShareX24 } from "../../../resources/icons/ic-share-x.svg";
import { ReactComponent as IcShareMail24 } from "../../../resources/icons/ic-share-mail.svg";
import { ReactComponent as IcShareCopy24 } from "../../../resources/icons/ic-share-copy-link.svg";
import { FeatureCollection, LineString } from "geojson";
import { TTMLocation, TTMLocationContext, TTMWaypoint } from "../../utils/locationTypes";
import { getLocationInfo } from "../../utils/location";
import SendDestination from "../elements/SendDestination";

import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import "./LocationActions.scss";
import { RetryAction } from "../../state/tree/application/reducers";

const locationActions = bem("preview-panel-actions");
type ActionType = "add" | "remove" | "from" | "to";

type ExternalProps = {
    shownLocation: TTMLocation;
    className?: string;
    mode: "panel" | "popup";
    filledWaypointsLength: number;
    waypoints: TTMWaypoint[];
    routeFeatures: FeatureCollection<LineString>;
    activeRouteIndex: number;
    handleRouteAction: (forcedIndex?: number) => void;
    handleOpenEditModal: (point: [number, number]) => void;
    handleSetRetryAction: (retryAction: RetryAction, point: [number, number]) => void;
    authenticated: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onSendToDevice?: () => void;
    isActiveDestination?: boolean;
    featureConfigs?: { [key: string]: unknown };
};

type Props = WithTranslation & ExternalProps;

const waypointSetIndex = (actionType: ActionType): number => {
    const determineActionIndex = actionType === "to" ? 1 : null;
    return actionType === "from" ? 0 : determineActionIndex;
};

const getActionDataQaId = (actionType: ActionType): string => {
    switch (actionType) {
        case "add":
            return "add-stop";
        case "remove":
            return "remove-stop";
        case "from":
            return "directions-from";
        case "to":
            return "directions-to";
    }
};

class LocationActionsCmp extends Component<Props> {
    private getPrimaryActionType = (): ActionType => {
        const { filledWaypointsLength, shownLocation, waypoints } = this.props;
        if (filledWaypointsLength >= 1 && shownLocation?.context === TTMLocationContext.WAYPOINT) {
            return "remove";
        } else if (filledWaypointsLength > 1) {
            return "add";
        } else if (waypoints[1]) {
            return "from";
        } else {
            return "to";
        }
    };

    private getSecondaryActionType = (): ActionType => {
        const { filledWaypointsLength, waypoints, shownLocation } = this.props;
        if (filledWaypointsLength < 2 && shownLocation?.context !== TTMLocationContext.WAYPOINT) {
            if (waypoints[1]) {
                return "to";
            } else {
                return "from";
            }
        } else {
            return null;
        }
    };
    private handleCopyURL = () => {
        navigator.clipboard.writeText(location.href);
    };

    render() {
        const primaryActionType = this.getPrimaryActionType();
        const secondaryActionType = this.getSecondaryActionType();
        const { t, authenticated } = this.props;

        const handleOpenEditModal = () => {
            const point = getLocationInfo(this.props.shownLocation)?.point;
            if (authenticated) {
                this.props.handleOpenEditModal(point);
            } else {
                this.props.handleSetRetryAction(
                    {
                        retryActionName: "open_suggest_edit_modal",
                        retryActionPayload: null
                    },
                    point
                );
            }
        };

        return (
            <div
                className={classNames(locationActions.block(), this.props.className)}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                {this.props.featureConfigs.vertexFeedback && (
                    <IconSelect
                        type="accent"
                        icon={<IcEdit24 />}
                        value="Suggest an edit"
                        className={locationActions.element("suggest-edit")}
                        onClick={handleOpenEditModal}
                        qaIdModifier={"suggest-edit"}
                    />
                )}
                {secondaryActionType ? (
                    <div className="btn-group">
                        <ButtonPrimary
                            onClick={() => this.props.handleRouteAction(waypointSetIndex(primaryActionType))}
                            text={t(primaryActionType)}
                            data-qa-id={getActionDataQaId(primaryActionType)}
                        />
                        <ButtonPrimary
                            className="dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown"
                            data-qa-id="actions-dropdown-btn"
                        />

                        <ul className="dropdown-menu">
                            <li>
                                <a
                                    className="dropdown-item"
                                    data-qa-id={getActionDataQaId(secondaryActionType)}
                                    onClick={() => this.props.handleRouteAction(waypointSetIndex(secondaryActionType))}
                                >
                                    {t(secondaryActionType)}
                                </a>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <ButtonPrimary
                        className={"preview-panel-actions__create-route"}
                        onClick={() => this.props.handleRouteAction()}
                        text={t(primaryActionType)}
                        data-qa-id={getActionDataQaId(primaryActionType)}
                    />
                )}

                {this.props.mode === "panel" && (
                    <div className={locationActions.element("actions")}>
                        <div className={classNames(locationActions.element("share"), "dropdown")}>
                            <ButtonContainer data-bs-toggle="dropdown">
                                <IconSelect
                                    type="secondary"
                                    icon={<IcShare16 />}
                                    value={t("RouteView:share")}
                                    className={classNames(locationActions.element("share-dropdown"), "dropdown-toggle")}
                                />
                            </ButtonContainer>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <a
                                        className={classNames(
                                            "dropdown-item fb-xfbml-parse-ignore",
                                            locationActions.element("share-fb")
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={t("RouteView:facebook")}
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                            location.href
                                        )}`}
                                    >
                                        <IcShareFb24 className="dropdown-item__icon" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item"
                                        title={t("RouteView:email")}
                                        // eslint-disable-next-line max-len
                                        href={`mailto:?subject=Check%20out%20this%20location&body=To%20view%20it%20in%20your%20browser%20click%20on%20this%20link:%0A%0A${location.href}`}
                                    >
                                        <IcShareMail24 className="dropdown-item__icon" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item"
                                        onClick={this.handleCopyURL}
                                        title={t("RouteView:copy")}
                                    >
                                        <IcShareCopy24 className="dropdown-item__icon" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={t("RouteView:x")}
                                        href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20place:%0A${location.href}`}
                                    >
                                        <IcShareX24 className="dropdown-item__icon" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <SendDestination
                            onSendToDevice={this.props.onSendToDevice}
                            isActiveDestination={this.props.isActiveDestination}
                            qaIdModifier={"location-actions"}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const Wrapped = withTranslation(["LocationActions", "RouteView"])(LocationActionsCmp);

const LocationActions = (props: ExternalProps) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <Wrapped {...props} />
    </IsomorphicSuspense>
);

export default LocationActions;
