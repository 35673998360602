import React, { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { ItineraryPlanningRequest, UnitsType } from "@anw/gor-sdk";
import { Empty, qaId } from "../../ui-library";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";

import { calcDistanceTime, defaultDistanceTime } from "../../utils/distanceTimeCalc";
import { TTMLocation } from "../../utils/locationTypes";
import { getPoint } from "../../utils/location";
import { PlannedRouteInformation } from "../../state/tree/map-page/planner/reducers";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import { ReactComponent as IcRoute32 } from "../../../resources/icons/ic-route-32.svg";

const distanceTimeQa = qaId("distance-time");

type Props = {
    filledWaypointsLength: number;
    plannerParams: ItineraryPlanningRequest;
    plannedRouteInfo: PlannedRouteInformation;
    activeRouteIndex: number;
    location: TTMLocation;
    userLngLat: [number, number];
    locationAccuracy: number;
    unitsType: UnitsType;
};

const DistanceTimeCmp = (props: Props) => {
    const {
        filledWaypointsLength,
        userLngLat,
        unitsType,
        plannerParams,
        location,
        plannedRouteInfo,
        activeRouteIndex
    } = props;
    const isRoutePlanned = filledWaypointsLength > 1;
    const [distanceTime, setDistanceTime] = useState(defaultDistanceTime);

    const prevLocation = usePrevious(location);
    const { t } = useTranslation("DistanceTime");

    useEffect(() => {
        if (!prevLocation || !isEqual(getPoint(prevLocation), getPoint(location))) {
            const calcAndSetDistanceTime = async () => {
                setDistanceTime(defaultDistanceTime);
                const distanceTime = await calcDistanceTime({
                    isRoutePlanned,
                    currentPlannerParams: plannerParams,
                    plannedRouteInfo,
                    activeRouteIndex,
                    location,
                    userLngLat,
                    unitsType
                });
                setDistanceTime(distanceTime);
            };

            calcAndSetDistanceTime();
        }
    }, [
        prevLocation,
        location,
        userLngLat,
        plannerParams,
        plannedRouteInfo,
        unitsType,
        isRoutePlanned,
        activeRouteIndex
    ]);

    const { time, distance, isNegativeDistance, isNegativeTime, isLongDistance } = distanceTime;

    return (
        <>
            {(time || distance) && (
                <div className="distance-time" {...distanceTimeQa.block()}>
                    {isRoutePlanned ? (
                        <>
                            <IcRoute32 className="distance-time__route-icon" />
                            <ul className="distance-time__info">
                                {distance && (
                                    <li {...distanceTimeQa.element("distance")}>
                                        {isLongDistance && t("DistanceTime:approx") + " "}
                                        {t(
                                            isNegativeDistance
                                                ? "DistanceTime:distance_from_route_shorter"
                                                : "DistanceTime:distance_from_route_longer",
                                            { distance: distance.replace(/^-/, "") }
                                        )}
                                    </li>
                                )}
                                {time && (
                                    <li {...distanceTimeQa.element("time")}>
                                        {t(
                                            isNegativeTime
                                                ? "DistanceTime:time_from_route_faster"
                                                : "DistanceTime:time_from_route_slower",
                                            {
                                                time
                                            }
                                        )}
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (
                        userLngLat && (
                            <span {...distanceTimeQa.element("from-location")}>
                                {t("DistanceTime:distance_time_from_location", {
                                    time,
                                    distance: distance.replace(/^-/, "")
                                })}
                            </span>
                        )
                    )}
                </div>
            )}
        </>
    );
};

const DistanceTime = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <DistanceTimeCmp {...props} />
    </IsomorphicSuspense>
);

export default DistanceTime;
