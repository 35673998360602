import React from "react";
import { Empty, IcClock24 } from "../../ui-library";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { selectUnitsType } from "../../state/tree/map-page/settings/selectors";
import { BaseSearchItem, Heading } from "./SearchAreaItem";
import { TTMLocationContext, TTMSearchResult } from "../../utils/locationTypes";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

type RecentSearchesProps = {
    recentSearches: TTMSearchResult[];
    onClick: (searchValue: TTMSearchResult, index: number) => void;
};

const RecentSearchesBlockCmp = ({ recentSearches, onClick }: RecentSearchesProps) => {
    const unitType = useSelector(selectUnitsType);
    const { t } = useTranslation("RecentSearchesBlock");
    return (
        recentSearches.length > 0 && (
            <>
                <Heading text={t("RecentSearchesBlock:heading")} />
                {recentSearches.map((recentSearch, index) => (
                    <BaseSearchItem
                        key={recentSearch.externalID}
                        result={{ ...recentSearch, context: TTMLocationContext.SEARCH_HISTORY }}
                        unitType={unitType}
                        icon={<IcClock24 />}
                        onClick={() => onClick(recentSearch, index)}
                        dataQaId={`recent-search-${index}`}
                    />
                ))}
            </>
        )
    );
};

const RecentSearchesBlock = (props: RecentSearchesProps) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <RecentSearchesBlockCmp {...props} />
    </IsomorphicSuspense>
);

export default RecentSearchesBlock;
