import React, { InputHTMLAttributes } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Search } from "../../ui-library";

import { AppDispatchProp, RootState } from "../../state/RootReducer";
import { actions as locationActions } from "../../state/tree/map-page/location/reducers";
import { actions as searchActions } from "../../state/tree/map-page/search";
import {
    selectInputString,
    selectIsAutoCompleteSegmentSelectedByUser,
    selectSearchNeedsUpdate
} from "../../state/tree/map-page/search/selectors";
import { retrieveQueryFromSearch } from "../../state/tree/navigation/selectors";
import { navigateHome } from "../../state/tree/navigation/thunks";
import { highlightSearchResults } from "../../utils/search";
import { URL_PATH_MAP } from "../elements/Links";
import { selectForeground } from "../../state/tree/application/selectors";
import { ForegroundOption } from "../../state/tree/application/reducers";
import { TealiumLogger } from "../../classes/TealiumLogger";
import { isExactURLPathMatch } from "../../classes/localization/Localization";
import { WithTranslation, withTranslation } from "react-i18next";

type FetchCaller = (arg: { query: string }) => Promise<void>;

const fetchSearch = async (value: string, fetchAction: FetchCaller, clearAction: () => void) => {
    if (value.length > 0) {
        await fetchAction({ query: value });
    } else {
        clearAction();
    }
};

const debounceFetchSearchResults = debounce(fetchSearch, 300);

const mapStateToProps = (state: RootState) => ({
    searchValue: selectInputString(state),
    isAutoCompleteSegmentSelectedByUser: selectIsAutoCompleteSegmentSelectedByUser(state),
    searchNeedsUpdate: selectSearchNeedsUpdate(state),
    searchValueFromURL: retrieveQueryFromSearch(state),
    foreground: selectForeground(state),
    pathname: state.router.location.pathname
});

type Props = WithTranslation &
    AppDispatchProp &
    ReturnType<typeof mapStateToProps> &
    InputHTMLAttributes<HTMLInputElement>;

class SearchForm extends React.Component<Props> {
    componentDidMount() {
        this.props.dispatch(searchActions.changeSearchInput(this.props.searchValueFromURL));
    }

    handleSubmit = async (inputQuery: string) => {
        // if the map position was updated, re-fetch search on submit
        // if last category/brand was selected by user, re-fetch unfiltered search to detect new category/brand
        if (this.props.searchNeedsUpdate || this.props.isAutoCompleteSegmentSelectedByUser) {
            // cancel any debounced search that didn't run, as the search call will be done here anyway
            debounceFetchSearchResults.cancel();
            await this.props.dispatch(
                searchActions.fetchSearchAndAutocompleteSuggestions({
                    query: inputQuery,
                    isFinalCall: false
                })
            );
        } else {
            // If there is a pending debounced search call, do it immediately
            // this is to handle the case when user types fast and press enter before the last search debounce duration pass
            await debounceFetchSearchResults.flush();
        }
        this.props.dispatch(searchActions.updateIsAutoCompleteSegmentSelectedByUser(false));
        this.props.dispatch(searchActions.confirmSearchInput(true));
    };

    handleFormSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        // force search input box to lose focus on form submit
        (ev.target[0] as HTMLInputElement).blur();
        this.handleSubmit(this.props.searchValue);
    };

    handleFetchSearch = async (arg: { query: string }) => {
        await this.props.dispatch(searchActions.fetchSearch(arg));
    };

    handleClearInputQuery = () => {
        this.props.dispatch(searchActions.clearSearchResults());
        this.props.dispatch(locationActions.setSelectedLocation(null));
    };

    handleOnChange = (ev) => {
        const value = ev.currentTarget.value;
        this.props.dispatch(searchActions.changeSearchInput(value));
        debounceFetchSearchResults(value, this.handleFetchSearch, this.handleClearInputQuery);
    };

    handleClearSearchForm = () => {
        TealiumLogger.link({
            event_name: "clear_search",
            search_query_length: this.props.searchValue?.length
        });
        this.props.dispatch(searchActions.clearSearch());
    };

    handleFocusSearchInput = () => {
        this.props.dispatch(searchActions.setSearchFormFocused(true));
        !isExactURLPathMatch(this.props.pathname, URL_PATH_MAP.INDEX) && this.props.dispatch(navigateHome());
        this.props.dispatch(locationActions.setSelectedLocation(null));
        // slight delay to insure the search results are shown because if we are on details page it takes
        // a little bit of time to draw again the search results, without delay it doesn't work
        setTimeout(() => highlightSearchResults(this.props.searchValue), 100);
    };

    handleBlurSearchInput = () => {
        // in case user clicks on recent search result we don't run into race condition
        //TODO: this needs to change to a better solution to avoid the glitch that happens from keeping recent searches 200ms while showing "result not found" error immediately
        setTimeout(() => this.props.dispatch(searchActions.setSearchFormFocused(false)), 200);
    };

    textToShow = () => {
        if (this.props.foreground === ForegroundOption.ROUTE_PLANNER) {
            return "";
        } else {
            return this.props.searchValue;
        }
    };

    placeholderToShow = () => {
        const { t } = this.props;
        if (this.props.foreground === ForegroundOption.ROUTE_PLANNER) {
            return t("SearchForm:return_to_search");
        } else {
            return t("SearchForm:search_for_place");
        }
    };

    render() {
        return (
            <form onSubmit={this.handleFormSubmit}>
                <Search
                    value={this.textToShow()}
                    onChange={this.handleOnChange}
                    onFocus={this.handleFocusSearchInput}
                    onClear={this.handleClearSearchForm}
                    data-testid="search-form-input"
                    id={this.props.id}
                    aria-label="Search input"
                    placeholder={this.placeholderToShow()}
                    onBlur={this.handleBlurSearchInput}
                    autoComplete="off"
                />
            </form>
        );
    }
}

export default withTranslation("SearchForm")(connect(mapStateToProps)(SearchForm));
