import { ButtonPrimary, Empty, IcSearch16, qaId } from "../../ui-library";
import React from "react";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";

import { actions, selectors } from "../../state/tree/map-page/search";
import { AppDispatchProp, RootState } from "../../state/RootReducer";
import { selectViewableMapBBox } from "../../state/tree/map-page/map/selectors";
import { SearchIntention } from "../../state/tree/map-page/search/types";
import { selectForeground } from "../../state/tree/application/selectors";
import { ForegroundOption } from "../../state/tree/application/reducers";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import "./SearchAreaButton.scss";

const searchAreaQa = qaId("search-area");

const mapStateToProps = (state: RootState) => ({
    showSearchArea: (() => {
        const searchValue = selectors.selectInputString(state);
        const matchedAutoCompleteSearch = selectors.selectMatchedAutoCompleteSearch(state);
        const searchNeedsUpdate = selectors.selectSearchNeedsUpdate(state);
        const foreground = selectForeground(state);
        return (
            searchValue &&
            foreground === ForegroundOption.DEFAULT_SEARCH &&
            matchedAutoCompleteSearch?.searchIntention === SearchIntention.DISCOVERY &&
            (matchedAutoCompleteSearch?.matchedBrand || matchedAutoCompleteSearch?.matchedCategory) &&
            searchNeedsUpdate
        );
    })(),
    mapViewableBBox: selectViewableMapBBox(state)
});

type Props = WithTranslation & AppDispatchProp & ReturnType<typeof mapStateToProps>;

class SearchAreaButtonCmp extends React.Component<Props> {
    handleSearchThisArea = () => {
        this.props.dispatch(actions.fetchSearchThisArea());
    };

    render = () => {
        const { t } = this.props;
        return (
            <>
                {this.props.showSearchArea && (
                    <ButtonPrimary
                        className="search-this-area"
                        onClick={this.handleSearchThisArea}
                        icon={<IcSearch16 />}
                        text={t("SearchAreaButton:text")}
                        {...searchAreaQa.block()}
                    />
                )}
            </>
        );
    };
}

const Wrapped = withTranslation("SearchAreaButton")(connect(mapStateToProps)(SearchAreaButtonCmp));
const SearchAreaButton = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <Wrapped />
    </IsomorphicSuspense>
);

export default SearchAreaButton;
