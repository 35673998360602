import Cookies, { CookieAttributes } from "js-cookie";

const COOKIE_NAME = "tt_settings";

export const hasCookiePanel = () => {
    const cookieValue = Cookies.get(COOKIE_NAME);
    return !cookieValue;
};

export const setAcceptanceCookie = (preferences: string[]) => {
    const options: CookieAttributes = {
        expires: 365,
        sameSite: "lax"
    };
    const value = {
        url: document.location.href,
        advertising: preferences.includes("advertising"),
        social: preferences.includes("social")
    };

    Cookies.set(COOKIE_NAME, JSON.stringify(value), options);
};
