import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { bem, Body, ButtonPrimary, Empty, Il404, qaId, Title } from "../../ui-library";
import { useTranslation } from "react-i18next";
import "bootstrap";

import { TealiumLogger } from "../../classes/TealiumLogger";
import RemoteLogger from "../../classes/RemoteLogger";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { navigateToPrevious } from "../../state/tree/navigation/thunks";
import { useAppDispatch } from "../../state/RootReducer";

import "./404.scss";
import "../../../sass/main.scss";

const page404Bem = bem("page-404");
const page404QA = qaId("page-404");

const Page404Cmp = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("404");

    useEffectOnce(() => {
        TealiumLogger.view({
            page_title: "404 page"
        });
    });

    RemoteLogger.log({
        message: "Redirect to 404",
        category: "404",
        data: {
            location: {
                pathname: location.pathname,
                hash: location.hash,
                search: location.search
            }
        },
        severity: "warn"
    });

    const goBack = () => {
        dispatch(navigateToPrevious());
    };

    return (
        <div className="page hero is-fullheight-with-navbar is-warning">
            <Helmet>
                <title>{t("404:helmet_title")}</title>
            </Helmet>
            <div className={page404Bem.block()} {...page404QA.block()}>
                <Il404 className={page404Bem.element("image")} {...page404QA.element("image")} />
                <Title className={page404Bem.element("title")} {...page404QA.element("title")}>
                    {t("404:page_title")}
                </Title>
                <Body className={page404Bem.element("body")} {...page404QA.element("body")}>
                    {t("404:page_body")}
                </Body>
                <ButtonPrimary
                    className={page404Bem.element("button")}
                    text={t("404:link_back")}
                    onClick={goBack}
                    {...page404QA.element("button")}
                />
            </div>
        </div>
    );
};

const Page404 = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <Page404Cmp />
    </IsomorphicSuspense>
);

export default Page404;
