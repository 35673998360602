import React, { useRef, useState } from "react";
import { bem, ButtonContainer, ButtonPrimary, Empty, Hyperlink, qaId, Title } from "../../ui-library";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

import { selectAuthentication } from "../../state/tree/authentication/selectors";
import { selectUserProfile } from "../../state/tree/user/selectors";
import { useAppDispatch } from "../../state/RootReducer";
import { signOut } from "../../state/tree/authentication/thunks";
import { logEventWithActiveMode } from "../../state/tree/application/thunks";
import { actions as applicationActions } from "../../state/tree/application/reducers";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { selectMapOptionsDisplayed } from "../../state/tree/map-page/map-controls/selectors";

import { ReactComponent as IcUserReported24 } from "../../../resources/icons/ic-user-reported-24.svg";
import { ReactComponent as IcUserReported16 } from "../../../resources/icons/ic-user-reported-16.svg";

import "./LoginComponent.scss";
import { selectExternalLinks } from "../../state/tree/global-configuration/selectors";
import { supportedLocale } from "../../classes/localization/Localization";
import i18next from "i18next";

const loginComponentBem = bem("login-component");
const loginComponentQA = qaId("login-component");
const loggedInCardBem = bem("logged-in-card");
const loggedInCardQA = qaId("logged-in-card");

const LoginCmp: React.FC = () => {
    const authenticated = useSelector(selectAuthentication)?.authenticated;
    const userProfile = useSelector(selectUserProfile);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("LoginComponent");
    const [showLoggedInCard, setShowLoggedInCard] = useState(false);
    const mapOptionsDisplayed = useSelector(selectMapOptionsDisplayed);

    const handleSignOut = () => {
        setShowLoggedInCard(false);
        dispatch(signOut(true));
    };

    const handleLoginButtonClick = () => {
        dispatch(applicationActions.setLoginAnalyticsInfo({ method: "ttm", trigger: "login_button" }));
        dispatch(
            logEventWithActiveMode({
                event_name: "login",
                method: "ttm",
                trigger: "login_button",
                status: "initiated"
            })
        );
    };

    // https://blog.logrocket.com/detect-click-outside-react-component-how-to/
    const ref = useRef(null);
    useEffectOnce(() => {
        document.addEventListener(
            "mousedown",
            (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowLoggedInCard(false);
                }
            },
            true
        );
    });

    const externalLinks = useSelector(selectExternalLinks);

    return (
        <div
            ref={ref}
            className={classNames(
                loginComponentBem.block(),
                mapOptionsDisplayed && loginComponentBem.modifier("map-layers-panel-open")
            )}
            {...loginComponentQA.block()}
        >
            {authenticated ? (
                <ButtonContainer
                    onClick={() => setShowLoggedInCard(!showLoggedInCard)}
                    {...loginComponentQA.element("logged-in-icon")}
                >
                    <IcUserReported24
                        className={classNames(loginComponentBem.element("logged-in-icon"), {
                            [loginComponentBem.modifier("active")]: showLoggedInCard
                        })}
                    />
                </ButtonContainer>
            ) : (
                <ButtonPrimary
                    className={loginComponentBem.element("login-button")}
                    icon={<IcUserReported16 />}
                    text={t("LoginComponent:sign_in")}
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                    {...loginComponentQA.element("login-button")}
                    onClick={() => handleLoginButtonClick()}
                />
            )}
            {showLoggedInCard && (
                <div className={loggedInCardBem.block()} {...loggedInCardQA.block()}>
                    <Title size="s" className={loggedInCardBem.element("title")} {...loggedInCardQA.element("title")}>
                        {userProfile.username}
                    </Title>
                    <a
                        href={`${externalLinks.tomtomURL}/${supportedLocale[i18next.language]}/${
                            externalLinks.account_details
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={loggedInCardBem.element("account-link")}
                        {...loggedInCardQA.element("account-link")}
                    >
                        <Hyperlink>{t("LoginComponent:manage")}</Hyperlink>
                    </a>
                    <div className={loggedInCardBem.element("actions")}>
                        <ButtonPrimary
                            className={loggedInCardBem.element("logout-button")}
                            onClick={() => handleSignOut()}
                            text={t("LoginComponent:sign_out")}
                            {...loggedInCardQA.element("logout-button")}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const LoginComponent = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <LoginCmp />
    </IsomorphicSuspense>
);

export default LoginComponent;
