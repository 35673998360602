import React, { HTMLAttributes, ReactElement, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionProps } from "react-transition-group/CSSTransition";
import classNames from "classnames";

import { bem } from "../../ui-library";

import "./AnimationWrapper.scss";

const ANIMATION_DURATION_MS = 200;
const ANIMATION_DURATION_MS_FADE_IN_OUT = 300;
const animationWrapper = bem("animation-wrapper");

export type AnimationType =
    | "slide-in-down-out-up"
    | "slide-in-left-out-right"
    | "slide-in-right-out-left"
    | "move-in-left-out-right"
    | "fade-in-out";

const getAnimationDuration = (animationType: AnimationType) => {
    return animationType == "fade-in-out" ? ANIMATION_DURATION_MS_FADE_IN_OUT : ANIMATION_DURATION_MS;
};

type AnimationWrapperProps = HTMLAttributes<HTMLDivElement> & {
    show: boolean;
    animationClassNames?: CSSTransitionProps["classNames"];
    animationDurationMs?: number;
    component: ReactElement;
    wrapperClassNameModifier?: string | string[];
    animationType?: AnimationType;
    unmountOnExit?: boolean;
};

const AnimationWrapper = ({
    animationClassNames,
    show,
    wrapperClassNameModifier,
    component,
    animationType = "slide-in-down-out-up",
    animationDurationMs = getAnimationDuration(animationType),
    className,
    unmountOnExit = false,
    ...htmlAttributes
}: AnimationWrapperProps) => {
    const getHeight = useCallback((elem) => elem.offsetHeight, []);

    /* -- Expanding -- */
    const handleEnter = useCallback((elem) => {
        elem.style.display = "block";
    }, []);

    const handleEntering = useCallback(
        (elem) => {
            if (animationType === "slide-in-down-out-up") {
                //setting the element's full height so that it can transition to it
                elem.style.height = `${elem.scrollHeight}px`;
            }
        },
        [animationType]
    );

    const handleEntered = useCallback((elem) => {
        elem.style.height = null;
        elem.style.width = null;
        elem.style.display = null;
    }, []);

    /* -- Collapsing -- */
    const handleExit = useCallback(
        (elem) => {
            if (animationType === "slide-in-down-out-up") {
                //setting the element's current height so that it can transition from it
                elem.style.height = `${getHeight(elem)}px`;
            }
            elem.style.display = "block";
        },
        [animationType, getHeight]
    );

    const handleExiting = useCallback(
        (elem) => {
            if (animationType === "slide-in-down-out-up") {
                elem.style.height = "0";
            }
        },
        [animationType]
    );

    return (
        // @ts-ignore
        <CSSTransition
            in={show}
            timeout={animationDurationMs}
            classNames={animationClassNames || animationType}
            unmountOnExit={unmountOnExit}
            mountOnEnter={true}
            onEnter={handleEnter}
            onEntering={handleEntering}
            onEntered={handleEntered}
            onExit={handleExit}
            onExiting={handleExiting}
        >
            <div
                className={classNames(animationWrapper.block(), className, {
                    [animationWrapper.modifier(wrapperClassNameModifier)]: wrapperClassNameModifier
                })}
                {...htmlAttributes}
            >
                <div className={animationWrapper.element("content")}>{component}</div>
            </div>
        </CSSTransition>
    );
};

export default AnimationWrapper;
