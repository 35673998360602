import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { reverseGeocode as sdkReverseGeocode } from "@tomtom-international/web-sdk-services/esm";
import { useTranslation } from "react-i18next";

import { bem, Empty, IcRoundTrip24, ScrollBar } from "../../ui-library";
import { addressToTTMSearchResult, getLocationInfo } from "../../utils/location";
import { TTMLocation, TTMLocationContext, TTMSearchResult, TTMUserMapLocation } from "../../utils/locationTypes";
import { useAppDispatch } from "../../state/RootReducer";
import {
    selectInputString,
    selectRecentSearches,
    selectSearchFormFocused
} from "../../state/tree/map-page/search/selectors";
import { selectApiKey, selectServiceUrls } from "../../state/tree/global-configuration/selectors";
import { selectUserLngLat, selectUserLocationAccuracy, selectUserLocationInfo } from "../../state/tree/user/selectors";
import { selectUnitsType } from "../../state/tree/map-page/settings/selectors";
import {
    selectFilledWaypoints,
    selectIsRoundTrip,
    selectPlannerSearchQuery,
    selectWaypoints
} from "../../state/tree/map-page/planner/selectors";
import { updateRecentSearches } from "../../state/tree/map-page/search/thunks";
import { actions as userActions } from "../../state/tree/user/reducers";
import { BaseSearchItem, CurrentLocation } from "./SearchAreaItem";
import RecentSearchesBlock from "./RecentSearchesBlock";
import { currentLocationText } from "../planner/PlannerGeoInputs";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import { updateUserLocation } from "../../state/tree/user/thunks";
import { ServiceUrls } from "../../state/tree/global-configuration/reducers";
import { geolocateControl } from "../map/MapComponent";

import "./SearchResults.scss";
import { languageToAPILocale } from "../../classes/localization/Localization";
import i18next from "i18next";

const LazyNgsResults = React.lazy(() => import("./NgsResults"));
const LazySearchResultItem = React.lazy(() => import("./SearchResultItem"));

export const searchResultsBem = bem("search-results");

export const reverseGeocode = async (
    lngLat: { lat: number; lng: number },
    apiKey: string,
    language: string,
    serviceUrls: ServiceUrls
): Promise<TTMSearchResult> => {
    // @ts-ignore
    const response = await sdkReverseGeocode(
        { key: apiKey, position: lngLat, language: languageToAPILocale[language] },
        {
            // @ts-ignore
            customEndpoints: {
                reverseGeocodeEndpoint: serviceUrls.reverseGeocodeEndpoint
            }
        }
    );
    const { address, position, id} = response.addresses[0];
    return addressToTTMSearchResult(address, position, id);
};

export enum AdditionalSearchOption {
    CURRENT_LOCATION,
    MY_PLACE,
    HISTORY
}

type Props = {
    onSelectSearchResult: (res: TTMLocation, index: number, additionalSearchOption?: AdditionalSearchOption) => void;
    onSelectAutocompleteSegment?: (res: TTMSearchResult, index: number) => void;
    myPlacesSearchResults?: TTMUserMapLocation[];
    historySearchResults?: TTMSearchResult[];
    searchResults?: TTMSearchResult[];
    focusedInputIndex?: number;
    onHoverResultsPanel?: () => void;
    onResultSelection?: () => void;
    mode?: "search" | "planner";
    onHoverSearchResult?: (res: TTMLocation, index: number) => void;
    onMouseLeaveSearchResult?: () => void;
    gotFocus?: boolean;
    className?: string;
};

const SearchResultsCmp = (props: Props) => {
    const dispatch = useAppDispatch();
    const apiKey = useSelector(selectApiKey);
    const serviceUrls = useSelector(selectServiceUrls);
    const unitType = useSelector(selectUnitsType);
    const searchValue = useSelector(selectInputString);
    const userLocation = useSelector(selectUserLngLat);
    const locationAccuracy = useSelector(selectUserLocationAccuracy);
    const userLocationInfo = useSelector(selectUserLocationInfo);
    const searchFormFocused = useSelector(selectSearchFormFocused);
    const recentSearches = useSelector(selectRecentSearches);
    const plannerSearchQuery = useSelector(selectPlannerSearchQuery);
    const waypoints = useSelector(selectWaypoints);
    const filledWaypoints = useSelector(selectFilledWaypoints);
    const isRoundTrip = useSelector(selectIsRoundTrip);
    const { t } = useTranslation("SearchResults");
    const { myPlacesSearchResults, historySearchResults, searchResults, focusedInputIndex } = props;
    // checking the string to make sure the user didn't start to change the input value but didn't confirm it yet,
    // as in this case the search results for the entered query should be shown instead of recent searches
    const isCurrentLocation =
        waypoints?.[focusedInputIndex]?.isCurrentLocation && plannerSearchQuery === currentLocationText;
    // it is the last waypoint AND there is 3 or more waypoints AND it's not a roundtrip already
    const showRoundTripOption = focusedInputIndex === waypoints.length - 1 && waypoints.length > 2 && !isRoundTrip;
    const hasResults = searchResults.length > 0;

    const pushResultToRecentSearches = (res: TTMLocation) => {
        // add new item on front, filter in case it was selected before and crop to max list size
        dispatch(updateRecentSearches(getLocationInfo(res)));
    };

    const handleSelectResult = (res: TTMLocation, index: number, additionalSearchOption?: AdditionalSearchOption) => {
        props.onSelectSearchResult(res, index, additionalSearchOption);
        additionalSearchOption !== AdditionalSearchOption.CURRENT_LOCATION && pushResultToRecentSearches(res);
    };

    const handleMyPlace = (res: TTMLocation, index: number) => {
        handleSelectResult(res, index, AdditionalSearchOption.MY_PLACE);
    };

    const handleHistory = (res: TTMLocation, index: number) => {
        handleSelectResult(res, index, AdditionalSearchOption.HISTORY);
    };

    const handleRoundTrip = (res: TTMLocation, index: number) => {
        const additionalSearchOption = waypoints[0].isCurrentLocation ? AdditionalSearchOption.CURRENT_LOCATION : null;
        handleSelectResult(res, index, additionalSearchOption);
    };

    const handleHoverResult = (res: TTMLocation, index: number) => {
        props.onHoverSearchResult?.(res, index);
    };

    const handleSearchResultMouseLeave = () => {
        props.onMouseLeaveSearchResult?.();
    };

    const handleAutocompleteSegmentClick = (res: TTMSearchResult, index: number) => {
        props.onSelectAutocompleteSegment?.(res, index);
    };

    const handleSelectCurrentLocation: PositionCallback = (position) => {
        const { coords } = position;
        const lngLat = { lng: coords.longitude, lat: coords.latitude };
        const userLocationReceived: [number, number] = [coords.longitude, coords.latitude];
        const locationAccuracy = coords.accuracy;
        const language = i18next.language;
        if (!isEqual(userLocation, userLocationReceived)) {
            // new current location
            dispatch(updateUserLocation({ lngLat: userLocationReceived, accuracy: locationAccuracy }));
            reverseGeocode(lngLat, apiKey, language, serviceUrls).then((res) => {
                dispatch(userActions.updateUserLocationInfo(res));
                handleSelectResult(
                    { ...res, context: TTMLocationContext.SEARCH_RESULT },
                    0,
                    AdditionalSearchOption.CURRENT_LOCATION
                );
            });
        } else if (!userLocationInfo) {
            // we don't have user location info yet
            reverseGeocode(lngLat, apiKey, language, serviceUrls).then((res) => {
                dispatch(userActions.updateUserLocationInfo(res));
                handleSelectResult(
                    { ...res, context: TTMLocationContext.SEARCH_RESULT },
                    0,
                    AdditionalSearchOption.CURRENT_LOCATION
                );
            });
        } else {
            // we both have user location and info, no need to do reverse geocode
            handleSelectResult(
                { ...userLocationInfo, context: TTMLocationContext.SEARCH_RESULT },
                0,
                AdditionalSearchOption.CURRENT_LOCATION
            );
        }
    };

    const handleCurrentLocation = () => {
        if (userLocation) {
            handleSelectCurrentLocation({
                coords: { longitude: userLocation[0], latitude: userLocation[1], accuracy: locationAccuracy }
            } as GeolocationPosition);
        } else {
            geolocateControl.once("geolocate", (event) => {
                // @ts-ignore because the event is not defined
                const coords = event.coords;
                handleSelectCurrentLocation({
                    coords: { longitude: coords.longitude, latitude: coords.latitude, accuracy: coords.accuracy }
                } as GeolocationPosition);
            });
            geolocateControl.trigger();
        }
    };

    const showRecentSearches = () => {
        if (props.mode !== "search") {
            return (!hasResults && plannerSearchQuery.length < 2) || isCurrentLocation;
        } else {
            return !hasResults && searchFormFocused && recentSearches?.length > 0 && searchValue == "";
        }
    };

    return (
        <div
            className={classNames(searchResultsBem.block(), props.className, {
                [searchResultsBem.modifier(props.mode)]: props.mode
            })}
        >
            <ScrollBar
                className={classNames(searchResultsBem.element("scroll-content"))}
                scrollableNodeProps={{
                    onMouseOver: props.onHoverResultsPanel,
                    onMouseLeave: props.onResultSelection
                }}
            >
                {showRecentSearches() && (
                    <div className={classNames("list-group", searchResultsBem.modifier("default"))}>
                        {props.mode !== "search" && (
                            <CurrentLocation
                                onHandleCurrentLocation={handleCurrentLocation}
                                dataQaId="current-location"
                            />
                        )}
                        {showRoundTripOption && (
                            <BaseSearchItem
                                text={t("SearchResults:round_trip")}
                                subTitle={" "}
                                result={{ ...waypoints[0].locationInfo, context: TTMLocationContext.SEARCH_RESULT }}
                                onClick={handleRoundTrip}
                                onHover={handleHoverResult}
                                onMouseLeave={handleSearchResultMouseLeave}
                                icon={<IcRoundTrip24 />}
                                dataQaId={"search-result-roundtrip"}
                                mode={props.mode}
                                plannerFocusedInputIndex={focusedInputIndex}
                            />
                        )}
                        <RecentSearchesBlock
                            recentSearches={recentSearches}
                            onClick={(res, index) =>
                                handleSelectResult({ ...res, context: TTMLocationContext.SEARCH_HISTORY }, index)
                            }
                        />
                    </div>
                )}
                {myPlacesSearchResults?.length > 0 && (
                    <div className={classNames("list-group", searchResultsBem.modifier("with-results"))}>
                        <IsomorphicSuspense fallback={<Empty />}>
                            {myPlacesSearchResults.map((myPlacesSearchResult, i) => (
                                <LazySearchResultItem
                                    key={myPlacesSearchResult.id}
                                    index={i}
                                    result={{ ...myPlacesSearchResult, context: TTMLocationContext.MY_PLACES }}
                                    unitType={unitType}
                                    userLocation={userLocation}
                                    onClick={handleMyPlace}
                                    onHover={handleHoverResult}
                                    onMouseLeave={handleSearchResultMouseLeave}
                                    dataQaId={`my-places-search-result-${i}`}
                                    mode={props.mode}
                                    plannerFocusedInputIndex={focusedInputIndex}
                                    isSaved={true}
                                />
                            ))}
                        </IsomorphicSuspense>
                    </div>
                )}
                {historySearchResults?.length > 0 && (
                    <div className={classNames("list-group", searchResultsBem.modifier("with-results"))}>
                        <IsomorphicSuspense fallback={<Empty />}>
                            {historySearchResults.map((historySearchResult, i) => (
                                <LazySearchResultItem
                                    key={historySearchResult.externalID}
                                    index={i}
                                    result={{ ...historySearchResult, context: TTMLocationContext.SEARCH_HISTORY }}
                                    unitType={unitType}
                                    userLocation={userLocation}
                                    onClick={handleHistory}
                                    onHover={handleHoverResult}
                                    onMouseLeave={handleSearchResultMouseLeave}
                                    dataQaId={`history-search-result-${i}`}
                                    mode={props.mode}
                                    plannerFocusedInputIndex={focusedInputIndex}
                                    isRecentSearch={true}
                                />
                            ))}
                        </IsomorphicSuspense>
                    </div>
                )}
                {hasResults && (
                    <div className={classNames("list-group", searchResultsBem.modifier("with-results"))}>
                        {props.mode !== "search" && (
                            <div className="current-location">
                                <CurrentLocation
                                    onHandleCurrentLocation={handleCurrentLocation}
                                    dataQaId="current-location"
                                />
                            </div>
                        )}
                        {showRoundTripOption && (
                            <BaseSearchItem
                                result={{ ...waypoints[0].locationInfo, context: TTMLocationContext.SEARCH_RESULT }}
                                onClick={handleSelectResult}
                                onHover={handleHoverResult}
                                onMouseLeave={handleSearchResultMouseLeave}
                                icon={<IcRoundTrip24 />}
                                dataQaId={"search-result-roundtrip"}
                                mode={props.mode}
                                plannerFocusedInputIndex={focusedInputIndex}
                            />
                        )}
                        <IsomorphicSuspense fallback={<Empty />}>
                            <LazyNgsResults
                                handleAutocompleteSegmentClick={handleAutocompleteSegmentClick}
                                results={searchResults}
                                unitType={unitType}
                                userLocation={userLocation}
                                handleSelectResult={handleSelectResult}
                                handleHoverResult={handleHoverResult}
                                handleSearchResultMouseLeave={handleSearchResultMouseLeave}
                                mode={props.mode}
                                focusedInputIndex={focusedInputIndex}
                                waypoints={waypoints}
                                filledWaypoints={filledWaypoints}
                            />
                        </IsomorphicSuspense>
                    </div>
                )}
            </ScrollBar>
        </div>
    );
};

const SearchResults = (props: Props) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <SearchResultsCmp {...props} />
    </IsomorphicSuspense>
);

export default SearchResults;
