import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { bem, Empty, IcClose24, InPanelMessage, InputControl, PasswordControl, useQaId } from "../../ui-library";
import classNames from "classnames";
import debounce from "lodash/debounce";
import Modal from "bootstrap/js/dist/modal";

import { useAppDispatch } from "../../state/RootReducer";
import { signIn } from "../../state/tree/authentication/thunks";
import { actions as applicationActions } from "../../state/tree/application/reducers";
import { selectLoginAnalyticsInfo, selectRetryAction } from "../../state/tree/application/selectors";
import { logEventWithActiveMode } from "../../state/tree/application/thunks";
import { selectAuthenticated } from "../../state/tree/authentication/selectors";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import { ReactComponent as IcForbidden40 } from "../../../resources/icons/ic-forbidden-40.svg";

import "./LoginModal.scss";
import { selectExternalLinks, selectLegalLinks } from "../../state/tree/global-configuration/selectors";
import { supportedLocale } from "../../classes/localization/Localization";
import i18next from "i18next";

const loginModal = bem("login-modal");

type LoginCredentials = {
    email: string;
    password: string;
};

const LoginModalCmp = () => {
    const dispatch = useAppDispatch();
    let loginForm;
    const closeRef = useRef<HTMLButtonElement>();
    const loginModalDivRef = useRef<HTMLDivElement>();
    const loginModalQa = useQaId("login-modal");
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);
    const [authenticationStarted, setAuthenticationStarted] = useState(false);
    const [authenticationSuccess, setAuthenticationSuccess] = useState(false);
    const retryAction = useSelector(selectRetryAction);
    const authenticated = useSelector(selectAuthenticated);
    const loginAnalyticsInfo = useSelector(selectLoginAnalyticsInfo);
    const { t } = useTranslation("LoginModal");

    useEffect(() => {
        if (loginModalDivRef && loginModalDivRef.current) {
            const handleLoginModalHidden = debounce(() => {
                if (!authenticationStarted) {
                    if (!authenticationSuccess) {
                        // we are closing the modal not because of authentication success
                        dispatch(
                            logEventWithActiveMode({
                                event_name: "login",
                                method: loginAnalyticsInfo?.method,
                                trigger: authenticated ? null : loginAnalyticsInfo?.trigger,
                                status: "canceled"
                            })
                        );
                    }
                    setAuthenticationSuccess(false);
                    dispatch(applicationActions.setRetryAction(null));
                }
            }, 200);
            const loginModalDivEl: HTMLDivElement = loginModalDivRef.current;
            loginModalDivEl.addEventListener("hidden.bs.modal", handleLoginModalHidden);
            return () => loginModalDivEl.removeEventListener("hidden.bs.modal", handleLoginModalHidden);
        }
    }, [
        loginModalDivRef,
        authenticationStarted,
        authenticationSuccess,
        retryAction,
        authenticated,
        loginAnalyticsInfo,
        dispatch
    ]);

    useEffect(() => {
        if (retryAction) {
            const modalLogin = Modal.getOrCreateInstance(loginModalDivRef.current);
            modalLogin.toggle();
            dispatch(
                logEventWithActiveMode({
                    event_name: "login",
                    method: loginAnalyticsInfo?.method,
                    trigger: authenticated ? null : loginAnalyticsInfo?.trigger,
                    status: "initiated"
                })
            );
        }
    }, [retryAction, loginAnalyticsInfo, authenticated, dispatch]);

    const handleSubmit = async (login: LoginCredentials) => {
        setShowError(false);
        setAuthenticationStarted(true);
        const loginAction = await dispatch(signIn({ login: login.email, password: login.password }));
        dispatch(
            logEventWithActiveMode({
                event_name: "login",
                method: loginAnalyticsInfo?.method,
                trigger: authenticated ? null : loginAnalyticsInfo?.trigger,
                status: loginAction["error"] ? "failed" : "success"
            })
        );
        if (!loginAction["error"]) {
            setShowError(false);
            setAuthenticationSuccess(true);
            // work around as having issue closing modal through javascript
            closeRef.current.click();
        } else {
            setShowError(true);
        }
        setAuthenticationStarted(false);
    };

    const handleFormCancel = () => {
        setShowError(false);
        setShowPassword(false);
        loginForm.reset();
    };

    const externalLinks = useSelector(selectExternalLinks);
    const legalLinks = useSelector(selectLegalLinks);

    return (
        <div
            className={classNames(loginModal.block(), "modal fade")}
            id="loginModal"
            tabIndex={-1}
            aria-labelledby="loginModalLabel"
            aria-hidden="true"
            ref={loginModalDivRef}
            {...loginModalQa.block()}
        >
            <div className="modal-dialog modal-dialog-centered" data-bs-focus={"true"}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="loginModalLabel" {...loginModalQa.element("heading")}>
                            {t("LoginModal:heading")}
                        </h5>
                        <span
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeRef}
                            onClick={handleFormCancel}
                            {...loginModalQa.element("close")}
                        >
                            <IcClose24 className="btn-close" />
                        </span>
                    </div>
                    {showError && (
                        <InPanelMessage
                            className={loginModal.element("error")}
                            image={<IcForbidden40 />}
                            message={t("LoginModal:error")}
                            {...loginModalQa.element("error")}
                        />
                    )}

                    <Form<LoginCredentials>
                        onSubmit={handleSubmit}
                        initialValues={{
                            email: "",
                            password: ""
                        }}
                        render={({ handleSubmit, form, submitting, pristine }) => {
                            loginForm = form;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <Field name="email">
                                                {(props) => (
                                                    <InputControl
                                                        name={props.input.name}
                                                        value={props.input.value}
                                                        onChange={props.input.onChange}
                                                        id="login-email"
                                                        label={t("LoginModal:email_label")}
                                                        error={showError}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="mb-3">
                                            <Field name="password">
                                                {(props) => {
                                                    return (
                                                        <PasswordControl
                                                            name={props.input.name}
                                                            id="login-password"
                                                            label={t("LoginModal:password_label")}
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                            showPassword={showPassword}
                                                            showPasswordText={t("LoginModal:show_password_text")}
                                                            onShowPasswordChange={(ev) =>
                                                                setShowPassword(ev.currentTarget.checked)
                                                            }
                                                            error={showError}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="login-modal__links-container">
                                            <a
                                                href={`${externalLinks?.tomtomURL}/${
                                                    supportedLocale[i18next.language]
                                                }/${externalLinks?.forgot_password}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="login-modal__forgot-password"
                                                {...loginModalQa.element("forgot-password")}
                                            >
                                                {t("LoginModal:forgot_password")}
                                            </a>
                                            <a
                                                href={`${externalLinks?.tomtomURL}/${
                                                    supportedLocale[i18next.language]
                                                }/${externalLinks?.create_account}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="login-modal__create-account-links"
                                                {...loginModalQa.element("create_account")}
                                            >
                                                {t("LoginModal:create_account_text")}
                                            </a>
                                            <a
                                                href={`${externalLinks?.tomtomURL}/${
                                                    supportedLocale[i18next.language]
                                                }/${legalLinks?.privacy}/`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="login-modal__legal-links"
                                                {...loginModalQa.element("legal-privacy")}
                                            >
                                                {t("LoginModal:privacy")}
                                            </a>
                                        </div>
                                        <div className="login-modal__buttons-container">
                                            <button
                                                type="reset"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                                onClick={handleFormCancel}
                                                {...loginModalQa.element("cancel")}
                                            >
                                                {t("LoginModal:cancel_button")}
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={submitting || pristine || authenticationStarted}
                                                {...loginModalQa.element("login")}
                                            >
                                                {t("LoginModal:login_button")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
const LoginModal = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <LoginModalCmp />
    </IsomorphicSuspense>
);

export default LoginModal;
