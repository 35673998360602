import { bem, Body, ButtonPrimary, ButtonSecondary, qaId, Title } from "../../ui-library";
import React from "react";
import classNames from "classnames";
import "./ConfirmationDialog.scss";
import { Portal } from "../elements/Portal";

const confirmationDialog = bem("confirmation-dialog");
const confirmationDialogQa = qaId("confirmation-dialog");

type ConfirmationDialogProps = {
    title: string;
    description: string;
    yesButtonTitle: string;
    noButtonTitle: string;
    onYesClicked(): void;
    onNoClicked?(): void;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    return (
        <Portal>
            <div
                {...confirmationDialogQa.element("backdrop")}
                className="modal-backdrop fade show"
                tabIndex={-1}
                aria-labelledby="confirmationDialogLabel"
                aria-hidden="true"
            />
            <div className={classNames(confirmationDialog.block(), "modal fade show")}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <Title size="s" className="modal-title" {...confirmationDialogQa.element("heading")}>
                                {props.title}
                            </Title>
                        </div>
                        <div className="modal-body">
                            <Body {...confirmationDialogQa.element("description")}>{props.description}</Body>
                        </div>
                        <div className="modal-footer">
                            <ButtonPrimary
                                text={props.noButtonTitle}
                                {...confirmationDialogQa.element("no")}
                                onClick={props.onNoClicked}
                            />
                            <ButtonSecondary
                                text={props.yesButtonTitle}
                                className={confirmationDialog.element("yes-button")}
                                {...confirmationDialogQa.element("yes")}
                                onClick={props.onYesClicked}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
};

export default ConfirmationDialog;
