import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { hasCookiePanel, setAcceptanceCookie } from "./CookiePanelUtils";
import CookieOptionInputs from "./CookieOptionInputs";
import "./cookiePanel.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { bem, Body, ButtonPrimary, ButtonSecondary } from "../../ui-library";

type CookiePanelPreferences = {
    value: string;
    label: string;
    checked?: boolean;
    disabled?: boolean;
};

const PREFERENCES_KEYS = ["functional", "analytical", "advertising", "social"];
export const MANDATORY_KEYS = ["functional", "analytical"];
export const cookiePanelBem = bem("cookie-panel");

const CookiePanel = () => {
    const { t } = useTranslation("CookiePanel");
    const [isPreferencesExpanded, setIsPreferencesExpanded] = useState(false);
    const [selectedCookiePreferences, setSelectedCookiePreferences] = useState<string[]>([]);
    const [cookieOptions, setCookieOptions] = useState<CookiePanelPreferences[]>([]);
    const [isPanelShown, setIsPanelShown] = useState(false);

    useEffect(() => {
        const prefList: CookiePanelPreferences[] = [];
        PREFERENCES_KEYS.forEach((value) => {
            let pref: CookiePanelPreferences = { value, label: t(value) };
            if (MANDATORY_KEYS.includes(value)) {
                pref = { ...pref, checked: true, disabled: true };
            }
            prefList.push(pref);
        });

        setCookieOptions(prefList);
        setIsPanelShown(hasCookiePanel());
    }, [t]);

    const onPreferencesChange = (data: string[]) => setSelectedCookiePreferences(data);

    const onAccept = (preferences: string[]) => {
        setAcceptanceCookie(preferences);
    };

    const handleAcceptClicked = () => {
        if (isPreferencesExpanded) {
            onAccept(selectedCookiePreferences);
        } else {
            onAccept(PREFERENCES_KEYS);
        }
        setIsPanelShown(false);
    };

    const panelText = isPreferencesExpanded ? t("expanded_body") : t("collapsed_body");
    const prefOptionsContainer = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 }
    };
    const prefTransition = { duration: 0.5 };

    return isPanelShown ? (
        <div className={classNames(cookiePanelBem.block())}>
            <Body>
                {panelText}
                &nbsp;
                <a target="_blank" href="https://www.tomtom.com/cookies" rel="noreferrer noopener">
                    tomtom.com/cookies
                </a>
            </Body>

            {isPreferencesExpanded && (
                <motion.div
                    className={classNames("overflow-hidden", cookiePanelBem.element("options-container"))}
                    initial="collapsed"
                    animate="open"
                    variants={prefOptionsContainer}
                    transition={prefTransition}
                >
                    <CookieOptionInputs options={cookieOptions} onChange={onPreferencesChange} />
                </motion.div>
            )}

            <div className={cookiePanelBem.element("btn-wrapper")}>
                <ButtonPrimary
                    text={!isPreferencesExpanded ? t("accept_all") : t("accept_save")}
                    onClick={handleAcceptClicked}
                />

                {!isPreferencesExpanded && (
                    <ButtonSecondary onClick={() => setIsPreferencesExpanded(true)} text={t("cookie_preferences")} />
                )}
            </div>
        </div>
    ) : null;
};

export default CookiePanel;
