import React, { HTMLAttributes, PropsWithChildren } from "react";
import { createPortal } from "react-dom";

export enum PORTAL_CONTAINER_IDS {
    PAGE_CONTAINER = "page-container",
    PORTAL_CONTAINER = "portal-container"
}

type PortalContainerProps = PropsWithChildren<
    Omit<HTMLAttributes<HTMLDivElement>, "id"> & { id: PORTAL_CONTAINER_IDS }
>;

export const PortalContainer = (props: PortalContainerProps) => <div {...props} />;

type PortalProps = PropsWithChildren<{ rootId?: PORTAL_CONTAINER_IDS | string; inBody?: boolean }>;

export const Portal = ({ rootId = PORTAL_CONTAINER_IDS.PORTAL_CONTAINER, children, inBody }: PortalProps) => {
    return createPortal(children, inBody ? document.body : document.getElementById(rootId) || document.body);
};
