import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { bem, Empty, ListsItem, ListsItemWithCheckbox, Panel, qaId, ScrollBar } from "../../ui-library";

import { useAppDispatch } from "../../state/RootReducer";
import { actions } from "../../state/tree/map-page/map-controls/reducers";
import { selectAuthenticated } from "../../state/tree/authentication/selectors";
import {
    selectHiddenPOIsCategoryGroupIconIDs,
    selectIsMyPlacesToggled,
    selectIsOtherPOIsToggled,
    selectIsSelectAllPOIsToggled,
    selectMapOptionsDisplayedFromRoadTripShortcut,
    selectMapOptionsDisplayedFromTrafficShortcut,
    selectMapStyleIDBeingEdited,
    selectScenicSegmentsToggled,
    selectRiderRoutesToggled,
    selectRoadTripsToggled,
    selectTrafficFlowToggled,
    selectTrafficIncidentsToggled
} from "../../state/tree/map-page/map-controls/selectors";
import {
    onMapStyleSelected,
    onMyPlacesDisplayToggle,
    onOtherPOIsToggle,
    onPOIsCategoryToggle,
    onScenicSegmentsToggled,
    onSelectAllPOIsToggle,
    onRoadTripsToggled,
    onTrafficFlowToggled,
    onTrafficIncidentsToggled,
    onRiderRoutesToggled
} from "../../state/tree/map-page/map-controls/userActionThunks";
import {
    selectFeatureConfigs,
    selectMapStylesByServiceSource,
    selectSelectedMapStyleID,
    selectServiceSource
} from "../../state/tree/global-configuration/selectors";
import { selectUserEntitledToRiderRoutes } from "../../state/tree/user/selectors";

import { POICategoryGroups } from "../../utils/mapControls";
import { MapStyle } from "../elements/MapStyle";

import IsomorphicSuspense from "../../classes/IsomorphicSuspense";
import defaultStyleIMG from "../../../resources/images/map_default_style@2x.png";
import lightStyleIMG from "../../../resources/images/map_light_style@2x.png";
import darkStyleIMG from "../../../resources/images/map_dark_style@2x.png";

import satelliteStyleIMG from "../../../resources/images/map_satellite@2x.png";
import "./MapOptionsPanel.scss";

const LazyMapStyleEdit = React.lazy(() => import("./MapStyleEdit"));

const mapOptionsPanelBem = bem("map-options-panel");
const mapControlsQa = qaId("map-controls");

const toImageSrc = (styleID: string) => {
    switch (styleID) {
        case "DEFAULT_LIGHT":
        case "OM":
            return lightStyleIMG;
        case "DEFAULT_DARK":
        case "OM_DARK":
            return darkStyleIMG;
        case "SATELLITE":
            return satelliteStyleIMG;
        default:
            return defaultStyleIMG;
    }
};

const MapOptionsPanelCmp = () => {
    const { t } = useTranslation("MapOptions");
    const dispatch = useAppDispatch();
    const trafficIncidentsToggled = useSelector(selectTrafficIncidentsToggled);
    const trafficFlowToggled = useSelector(selectTrafficFlowToggled);
    const scenicSegmentsToggled = useSelector(selectScenicSegmentsToggled);
    const roadTripsToggled = useSelector(selectRoadTripsToggled);
    const riderRoutesToggled = useSelector(selectRiderRoutesToggled);
    const userEntitledToRiderRoutes = useSelector(selectUserEntitledToRiderRoutes);
    const mapsStylesByEndPoint = useSelector(selectMapStylesByServiceSource);
    const selectedMapStyleID = useSelector(selectSelectedMapStyleID);
    const mapStyleIDBeingEdited = useSelector(selectMapStyleIDBeingEdited);
    const mapOptionsDisplayedFromTrafficShortcut = useSelector(selectMapOptionsDisplayedFromTrafficShortcut);
    const mapOptionsDisplayedFromRoadTripShortcut = useSelector(selectMapOptionsDisplayedFromRoadTripShortcut);
    const hiddenPOIsCategoryGroupIconIDs = useSelector(selectHiddenPOIsCategoryGroupIconIDs);
    const isMyPlacesToggled = useSelector(selectIsMyPlacesToggled);
    const isSelectAllPOIsToggled = useSelector(selectIsSelectAllPOIsToggled);
    const isOtherPOIsToggled = useSelector(selectIsOtherPOIsToggled);
    const authenticated = useSelector(selectAuthenticated);
    const serviceSource = useSelector(selectServiceSource);
    const featureConfigs = useSelector(selectFeatureConfigs);
    useEffect(() => {
        if (mapOptionsDisplayedFromRoadTripShortcut) {
            document.querySelector("#tomtom-road-trips")?.scrollIntoView({ behavior: "smooth" });
        }
    }, [mapOptionsDisplayedFromRoadTripShortcut]);

    return (
        <Panel
            className={mapOptionsPanelBem.block()}
            {...mapControlsQa.block()}
            heading={t("title")}
            onCloseClicked={() => dispatch(actions.toggleMapOptionsDisplay(false))}
        >
            <ScrollBar className={mapOptionsPanelBem.element("scroll")}>
                <div {...mapControlsQa.element("section-style")}>
                    <ListsItem asSubtitle title={t("map_styles")} />
                    <div className={mapOptionsPanelBem.element("map-styles-panel")}>
                        {mapsStylesByEndPoint.map((style) => (
                            <MapStyle
                                className={mapOptionsPanelBem.element("map-style")}
                                {...mapControlsQa.element(`style-${style.id}`)}
                                id={style.id}
                                key={style.id}
                                title={t(`styles.${style.id}`)}
                                imageSrc={toImageSrc(style.id)}
                                checked={style.id == selectedMapStyleID}
                                // See MenuLabFeatures component for the experimental styles toggling:
                                onClick={() => dispatch(onMapStyleSelected(style))}
                                tooltipMessage={serviceSource == "KOREA" && t("map_style_korea_tooltip")}
                                enableReselection={featureConfigs.userMapStyling}
                            />
                        ))}
                    </div>
                </div>
                {mapStyleIDBeingEdited ? (
                    <LazyMapStyleEdit />
                ) : (
                    <>
                        <div
                            {...mapControlsQa.element("section-traffic")}
                            className={mapOptionsPanelBem.element("section")}
                        >
                            <ListsItem asSubtitle title={t("traffic")} />
                            <ListsItemWithCheckbox
                                id="traffic-incidents"
                                key="traffic-incidents"
                                title={t("traffic_incidents")}
                                onChange={() => dispatch(onTrafficIncidentsToggled(!trafficIncidentsToggled))}
                                checked={trafficIncidentsToggled}
                                parentQaId={mapControlsQa.withModifier("incidents")}
                                blockElement={"list-toggle"}
                                position="left"
                                className={classNames(mapOptionsPanelBem.element("traffic-option"), {
                                    [mapOptionsPanelBem.element("traffic-option--focus")]:
                                        mapOptionsDisplayedFromTrafficShortcut
                                })}
                            />
                            <ListsItemWithCheckbox
                                id="traffic-flow"
                                key="traffic-flow"
                                title={t("traffic_flow")}
                                onChange={() => dispatch(onTrafficFlowToggled(!trafficFlowToggled))}
                                checked={trafficFlowToggled}
                                parentQaId={mapControlsQa.withModifier("flow")}
                                blockElement={"list-toggle"}
                                position="left"
                                className={mapOptionsPanelBem.element("traffic-option")}
                            />
                        </div>

                        {authenticated && (
                            <div {...mapControlsQa.element("places")} className={mapOptionsPanelBem.element("section")}>
                                <ListsItem asSubtitle title={t("places")} />
                                <ListsItemWithCheckbox
                                    title={t("my_places")}
                                    onChange={() => dispatch(onMyPlacesDisplayToggle(!isMyPlacesToggled))}
                                    checked={isMyPlacesToggled}
                                    parentQaId={mapControlsQa.withModifier("my_places")}
                                    blockElement={"list-toggle"}
                                    position="left"
                                    className={mapOptionsPanelBem.element("traffic-option")}
                                />
                            </div>
                        )}

                        <div {...mapControlsQa.element("pois")} className={mapOptionsPanelBem.element("section")}>
                            <ListsItem asSubtitle title={t("pois")} />
                            <ListsItemWithCheckbox
                                key={"all"}
                                title={t("all")}
                                onChange={() => dispatch(onSelectAllPOIsToggle(!isSelectAllPOIsToggled))}
                                checked={isSelectAllPOIsToggled}
                                parentQaId={mapControlsQa.withModifier("all")}
                                blockElement={"list-toggle"}
                                position="left"
                                className={mapOptionsPanelBem.element("traffic-option")}
                            />
                            {POICategoryGroups.map((categoryGroup) => (
                                <ListsItemWithCheckbox
                                    key={categoryGroup.label}
                                    title={t(categoryGroup.label)}
                                    onChange={() => dispatch(onPOIsCategoryToggle(categoryGroup))}
                                    checked={hiddenPOIsCategoryGroupIconIDs.every(
                                        (hiddenCatGroupIconIDs) =>
                                            !isEqual(categoryGroup.iconIDs, hiddenCatGroupIconIDs)
                                    )}
                                    parentQaId={mapControlsQa.withModifier(categoryGroup.label)}
                                    blockElement={"list-toggle"}
                                    position="left"
                                    className={mapOptionsPanelBem.element("traffic-option")}
                                />
                            ))}
                            <ListsItemWithCheckbox
                                key={"other"}
                                title={t("other")}
                                onChange={() => dispatch(onOtherPOIsToggle(!isOtherPOIsToggled))}
                                checked={isOtherPOIsToggled}
                                parentQaId={mapControlsQa.withModifier("other")}
                                blockElement={"list-toggle"}
                                position="left"
                                className={mapOptionsPanelBem.element("traffic-option")}
                            />
                        </div>
                        {!featureConfigs.hidden?.includes("roadTrips") && (
                            <div
                                {...mapControlsQa.element("road-trips")}
                                className={mapOptionsPanelBem.element("section")}
                            >
                                <ListsItem asSubtitle title={t("road-trips")} />
                                <ListsItemWithCheckbox
                                    id="scenic-segments"
                                    key="scenic-segments"
                                    title={t("scenic-segments")}
                                    onChange={() => dispatch(onScenicSegmentsToggled(!scenicSegmentsToggled))}
                                    checked={scenicSegmentsToggled}
                                    parentQaId={mapControlsQa.withModifier("scenic-segments")}
                                    blockElement={"list-toggle"}
                                    position="left"
                                    className={mapOptionsPanelBem.element("scenic-segments-option")}
                                />
                                <ListsItemWithCheckbox
                                    id="tomtom-road-trips"
                                    key="tomtom-road-trips"
                                    title="TomTom RoadTrips"
                                    onChange={() => dispatch(onRoadTripsToggled(!roadTripsToggled))}
                                    checked={roadTripsToggled}
                                    parentQaId={mapControlsQa.withModifier("tomtom-road-trips")}
                                    blockElement={"list-toggle"}
                                    position="left"
                                    className={classNames(mapOptionsPanelBem.element("road-trips-option"), {
                                        [mapOptionsPanelBem.element("road-trips-option--focus")]:
                                            mapOptionsDisplayedFromRoadTripShortcut
                                    })}
                                />
                                {userEntitledToRiderRoutes && (
                                    <ListsItemWithCheckbox
                                        id="tomtom-rider-collection"
                                        key="tomtom-rider-collection"
                                        title="Rider Routes"
                                        onChange={() => dispatch(onRiderRoutesToggled(!riderRoutesToggled))}
                                        checked={riderRoutesToggled}
                                        parentQaId={mapControlsQa.withModifier("tomtom-road-trips")}
                                        blockElement={"list-toggle"}
                                        position="left"
                                        className={classNames(mapOptionsPanelBem.element("road-trips-option"))}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )}
            </ScrollBar>
        </Panel>
    );
};

const MapOptionsPanel = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <MapOptionsPanelCmp />
    </IsomorphicSuspense>
);

export default MapOptionsPanel;
