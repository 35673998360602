import React, { ReactNode } from "react";
import {
    Body,
    Secondary,
    ListsItem,
    qaId,
    IcEvIndustrialBlue24,
    bem,
    IcEvIndustrialRed24,
    IcEvIndustrialWhite24,
    IcEvTeslaUsa24,
    IcEvGeneric24,
    IcEvType2Mennekes24,
    Empty,
    IcEvChademo24,
    IcEvType1Yasaki24,
    IcEvType3C24,
    IcEvType3A24,
    IcEvType324,
    IcEvUnknown24
} from "../../ui-library";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { EvPOI, EvInfoByConnector, getEvInfoByConnectors } from "../../utils/ev";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import "./EvConnector.scss";

const evConnectorBem = bem("ev-connector");
const evConnectorQa = qaId("ev-connector");

type ConnectionInfoByTypeType = { type: string; icon: ReactNode; nameKey: string };
const connectionInfoByType: ConnectionInfoByTypeType[] = [
    {
        type: "StandardHouseholdCountrySpecific",
        icon: <IcEvGeneric24 />,
        nameKey: "EvConnector:connector_types:domestic_connector"
    },
    { type: "IEC62196Type1", icon: <IcEvType1Yasaki24 />, nameKey: "EvConnector:connector_types:type_1_yazaki" },
    { type: "IEC62196Type1CCS", icon: <IcEvType1Yasaki24 />, nameKey: "EvConnector:connector_types:type_1_ccd" },
    { type: "IEC62196Type2", icon: <IcEvType2Mennekes24 />, nameKey: "EvConnector:connector_types:type_2_mennekes" },
    {
        type: "IEC62196Type2CableAttached",
        icon: <IcEvType2Mennekes24 />,
        nameKey: "EvConnector:connector_types:type_2_mennekes"
    },
    {
        type: "IEC62196Type2Outlet",
        icon: <IcEvType2Mennekes24 />,
        nameKey: "EvConnector:connector_types:type_2_mennekes"
    },
    { type: "IEC62196Type2CCS", icon: <IcEvType3A24 />, nameKey: "EvConnector:connector_types:type_2_ccs" },
    { type: "IEC62196Type3", icon: <IcEvType3C24 />, nameKey: "EvConnector:connector_types:type_3" },
    { type: "Chademo", icon: <IcEvChademo24 />, nameKey: "EvConnector:connector_types:chademo" },
    { type: "GBT20234Part2", icon: <IcEvType2Mennekes24 />, nameKey: "EvConnector:connector_types:GBT_part_2" },
    { type: "GBT20234Part3", icon: <IcEvType324 />, nameKey: "EvConnector:connector_types:gbt_part_3" },
    {
        type: "IEC60309AC1PhaseBlue",
        icon: <IcEvIndustrialBlue24 />,
        nameKey: "EvConnector:connector_types:industrial_blue"
    },
    {
        type: "IEC60309AC3PhaseRed",
        icon: <IcEvIndustrialRed24 />,
        nameKey: "EvConnector:connector_types:industrial_red"
    },
    {
        type: "IEC60309DCWhite",
        icon: <IcEvIndustrialWhite24 />,
        nameKey: "EvConnector:connector_types:industrial_white"
    },
    { type: "Tesla", icon: <IcEvTeslaUsa24 />, nameKey: "EvConnector:connector_types:tesla" }
];

type EvConnectorProps = {
    evInfoByConnectors: EvInfoByConnector[];
    compact?: boolean;
};

const EvConnector: React.FC<EvConnectorProps> = ({ evInfoByConnectors, compact }) => {
    const { t } = useTranslation("EvConnector");
    const unavailableText = compact ? "EvConnector:info_unavailable_compact" : "EvConnector:info_unavailable";
    const availableText = compact ? "EvConnector:available_compact" : "EvConnector:available";
    return (
        <>
            {evInfoByConnectors.map((info, index) => {
                return (
                    <ListsItem
                        className={classNames(evConnectorBem.block(), {
                            [evConnectorBem.modifier("available")]: info.availableConnectorsNumber > 0,
                            [evConnectorBem.modifier("unavailable")]:
                                info.availableConnectorsNumber == 0 && info.unknownConnectorsNumber == 0
                        })}
                        key={`ev-connector-${index}`}
                        title={
                            <>
                                <Body inline>
                                    {t(
                                        connectionInfoByType.find((connectionInfo) => connectionInfo.type == info.type)
                                            ?.nameKey ?? "EvConnector:connector_unknown"
                                    )}
                                </Body>
                                <Secondary inline> | {t("EvConnector:power", { power: info.powerKW })}</Secondary>
                            </>
                        }
                        trailingText={
                            info.unknownConnectorsNumber == info.totalConnectorsNumber
                                ? t(unavailableText)
                                : t(availableText, {
                                      available: info.availableConnectorsNumber,
                                      total: info.totalConnectorsNumber
                                  })
                        }
                        iconLeft={
                            connectionInfoByType.find((connectionInfo) => connectionInfo.type == info.type)?.icon ?? (
                                <IcEvUnknown24 />
                            )
                        }
                    />
                );
            })}
        </>
    );
};

type EvConnectorSectionProps = { evPoi: EvPOI; className?: string; compact?: boolean };

const EvConnectorSectionCmp = ({ evPoi, className, compact }: EvConnectorSectionProps) => {
    const { t } = useTranslation("EvConnector");
    return (
        (evPoi?.chargingParkId != "undefined" && (
            <div className={className} {...evConnectorQa.block()}>
                {!compact && <ListsItem title={t("EvConnector:connector")} asSubtitle />}
                <EvConnector evInfoByConnectors={getEvInfoByConnectors(evPoi.chargingStations)} compact={compact} />
            </div>
        )) || <Empty />
    );
};

const EvConnectorSection = (props: EvConnectorSectionProps) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <EvConnectorSectionCmp {...props} />
    </IsomorphicSuspense>
);

export default EvConnectorSection;
