import React, { MouseEventHandler, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import "simplebar-react/dist/simplebar.min.css";
import { bem, IcMenu24, IcRoute24, qaId, ButtonContainer } from "../../ui-library";
import { Toast } from "bootstrap";

import SearchForm from "../map-page/SearchForm";
import { PlannerLink, URL_PATH_MAP } from "./Links";
import { selectors } from "../../state/tree/map-page/planner";
import { selectPlannerCollapsed } from "../../state/tree/map-page/header/selectors";
import { headerActions } from "../../state/tree/map-page/header/reducers";
import { actions as locationActions } from "../../state/tree/map-page/location/reducers";
import { actions as applicationActions } from "../../state/tree/application/reducers";
import { useAppDispatch } from "../../state/RootReducer";
import { selectCurrentPlannerParams, selectRouteSelection } from "../../state/tree/map-page/planner/selectors";
import { TealiumLogger } from "../../classes/TealiumLogger";
import { isBaseURLPathMatch, isExactURLPathMatch } from "../../classes/localization/Localization";
import { useTranslation } from "react-i18next";

import "./Header.scss";

const headerBem = bem("header");
const headerQA = qaId("header");

type Mode =
    | "search"
    | "planner"
    | "saved-route-planner"
    | "location"
    | "planner-settings"
    | "my-items"
    | "itinerary-details"
    | "save-route"
    | "sync-route"
    | "settings"
    | "labs";
const isPlannerMode = (mode: Mode) => mode === "planner";

const getModeFromPath = (path: string): Mode => {
    if (isExactURLPathMatch(path, URL_PATH_MAP.INDEX)) {
        return "search";
    } else if (isExactURLPathMatch(path, URL_PATH_MAP.ROUTE_PLAN_SETTINGS)) {
        return "planner-settings";
    } else if (isExactURLPathMatch(path, URL_PATH_MAP.ROUTE_PLAN)) {
        return "planner";
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.LOCATION_BASE)) {
        return "location";
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_EDIT_LINE_BASE)) {
        return "saved-route-planner";
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.MY_ITEMS_BASE)) {
        return "my-items";
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_VIEW_BASE)) {
        return "itinerary-details";
    } else if (
        isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_NEW_BASE) ||
        isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_EDIT_BASE)
    ) {
        return "save-route";
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_SYNC_BASE)) {
        return "sync-route";
    } else if (isExactURLPathMatch(path, URL_PATH_MAP.SETTINGS)) {
        return "settings";
    } else if (isExactURLPathMatch(path, URL_PATH_MAP.LABS)) {
        return "settings";
    } else {
        return "search";
    }
};

const LeftButton = ({ onClick }: { onClick: () => void }) => (
    <ButtonContainer className={headerBem.element("left-action")} onClick={onClick} {...headerQA.element("main-menu")}>
        <IcMenu24 />
    </ButtonContainer>
);

const RightButton = ({ onClick }: { onClick: MouseEventHandler }) => (
    <div className={headerBem.element("right-action")}>
        <PlannerLink
            className={headerBem.element("planner-toggle")}
            {...headerQA.element("planner-toggle")}
            aria-label="planner toggle"
            onClick={onClick}
        >
            <IcRoute24 />
        </PlannerLink>
    </div>
);

const ActivePlannerToast = ({
    plannerActive,
    plannerCollapsed,
    mode
}: {
    plannerActive?: boolean;
    plannerCollapsed?: boolean;
    mode: Mode;
}) => {
    const toastRef = React.useRef<HTMLDivElement>();
    const { t } = useTranslation("Header");

    useEffect(() => {
        const toast = new Toast(toastRef.current);
        plannerActive && plannerCollapsed && ["planner", "search", "location"].includes(mode)
            ? toast.show()
            : toast.dispose();
    }, [mode, plannerActive, plannerCollapsed]);

    return (
        <div
            ref={toastRef}
            role="status"
            aria-live="polite"
            aria-atomic="true"
            className={classNames("toast", headerBem.element("toast"))}
            data-autohide="true"
            data-delay="1500"
            data-animation="true"
            {...headerQA.element("active-planner-toast")}
        >
            <div className="toast-body">{t("Header:route_toast")}</div>
        </div>
    );
};

const Header: React.FC = () => {
    const plannerCollapsed = useSelector(selectPlannerCollapsed);
    const plannerActive = useSelector(selectors.selectFilledWaypoints).length > 0;
    const routeParams = useSelector(selectCurrentPlannerParams);
    const activeRouteIndex = useSelector(selectRouteSelection);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const mode: Mode = getModeFromPath(location.pathname);

    const handleLeftButtonClick = () => {
        TealiumLogger.linkEventName("open_menu");
        dispatch(applicationActions.setIsMenuOpen(true));
    };

    const handleRightButtonClick = (event) => {
        if (mode === "planner") {
            dispatch(headerActions.setPlannerCollapsed(!plannerCollapsed));
            // not triggering link when ust collapsing the planner
            event.preventDefault();
        } else {
            dispatch(locationActions.setSelectedLocation(null));
            dispatch(headerActions.setPlannerCollapsed(false));
        }
    };

    useEffect(() => {
        if (!isPlannerMode(mode)) {
            dispatch(headerActions.setPlannerCollapsed(true));
        }
    }, [mode, dispatch]);

    useEffect(() => {
        if (isPlannerMode(mode)) {
            // making sure to show the route panel when a change in route parameters or active route index
            dispatch(headerActions.setPlannerCollapsed(false));
        }
    }, [routeParams, activeRouteIndex, mode, dispatch]);

    return (
        <div
            {...headerQA.block()}
            className={classNames(headerBem.block(), headerBem.modifier(mode), {
                [headerBem.modifier("planner-collapsed")]: plannerCollapsed,
                [headerBem.modifier("planner-active")]: plannerActive
            })}
        >
            <ActivePlannerToast mode={mode} plannerActive={plannerActive} plannerCollapsed={plannerCollapsed} />
            <div className={headerBem.element("header")}>
                <LeftButton onClick={handleLeftButtonClick} />
                <div className={headerBem.element("middle-area")}>
                    <SearchForm id="search" />
                </div>
                <RightButton onClick={handleRightButtonClick} />
            </div>
        </div>
    );
};

export default Header;
