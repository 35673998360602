import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { bem, ButtonSecondary, Empty, IcRoute24, IcTraffic24, qaId } from "../../ui-library";

import { useAppDispatch } from "../../state/RootReducer";
import {
    onRoadTripsShortcutToggled,
    onTrafficShortcutSelected
} from "../../state/tree/map-page/map-controls/userActionThunks";
import {
    selectAnyTrafficToggled,
    selectMapOptionsDisplayed,
    selectRoadTripsToggled
} from "../../state/tree/map-page/map-controls/selectors";
import { selectInputString, selectSearchFormFocused } from "../../state/tree/map-page/search/selectors";
import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import "./MapOptionShortcuts.scss";
import { selectFeatureConfigs } from "../../state/tree/global-configuration/selectors";

const mapOptionShortcutsBem = bem("map-option-shortcuts");
const mapOptionShortcutsQa = qaId("map-option-shortcuts");

const MapOptionShortcutsCmp = () => {
    const { t } = useTranslation("MapOptionShortcuts");
    const dispatch = useAppDispatch();
    const mapOptionsDisplayed = useSelector(selectMapOptionsDisplayed);
    const anyTrafficToggled = useSelector(selectAnyTrafficToggled);
    const isRoadTripsToggled = useSelector(selectRoadTripsToggled);
    const searchFormFocused = useSelector(selectSearchFormFocused);
    const searchInputString = useSelector(selectInputString);
    const featureConfig = useSelector(selectFeatureConfigs);
    const showTrafficShortcut = !mapOptionsDisplayed && !anyTrafficToggled && !searchFormFocused && !searchInputString;
    const showRoadTripsShortcut =
        !featureConfig.hidden?.includes("roadTrips") &&
        !mapOptionsDisplayed &&
        !isRoadTripsToggled &&
        !searchFormFocused &&
        !searchInputString;

    return showTrafficShortcut || showRoadTripsShortcut ? (
        <div className="shortcut-button-container">
            {showTrafficShortcut && (
                <ButtonSecondary
                    {...mapOptionShortcutsQa.element("traffic")}
                    className={mapOptionShortcutsBem.block()}
                    icon={<IcTraffic24 />}
                    text={t("MapOptionShortcuts:view_traffic")}
                    onClick={() => dispatch(onTrafficShortcutSelected())}
                />
            )}
            {showRoadTripsShortcut && (
                <ButtonSecondary
                    {...mapOptionShortcutsQa.element("road_trip_shortcut")}
                    className={mapOptionShortcutsBem.block()}
                    icon={<IcRoute24 />}
                    text={t("MapOptionShortcuts:view_roadTrips")}
                    onClick={() => dispatch(onRoadTripsShortcutToggled())}
                />
            )}
        </div>
    ) : (
        <Empty />
    );
};

const MapOptionShortcuts = () => (
    <IsomorphicSuspense fallback={<Empty />}>
        <MapOptionShortcutsCmp />
    </IsomorphicSuspense>
);

export default MapOptionShortcuts;
