import React from "react";
import classNames from "classnames";
import { bem } from "../../ui-library";
import "./PositionBullet.scss";

const positionBullet = bem("position-bullet");

type Props = Pick<React.PropsWithChildren<AnyObject>, "children">;

const PositionBullet = (props: Props) => {
    let classModifier = "";

    if (typeof props.children === "number") {
        classModifier = positionBullet.modifier("numbered");
    }

    return <span className={classNames(positionBullet.block(), classModifier)}>{props.children}</span>;
};

export default PositionBullet;
