import React, { HTMLAttributes, useState } from "react";
import { useEffectOnce } from "react-use";
import { ErrorBoundary } from "react-error-boundary";
import { useAppDispatch } from "../../../state/RootReducer";
import { actions as notificationActions } from "../../../state/tree/notification/reducers";
import { logBoundaryError } from "../../../utils/error";
import { useTranslation } from "react-i18next";

type Props = HTMLAttributes<HTMLElement> & {
    resetLogic: () => void;
    boundaryName: string;
};

type ErrorInfo = {
    error: Error;
    timeOfError: number;
};

const RecoveryErrorBoundary = (props: Props) => {
    const dispatch = useAppDispatch();

    const [failedRecovery, setFailedRecovery] = useState(false);
    const [errorInfo, setErrorInfo] = useState(null as ErrorInfo);
    const { t } = useTranslation("RecoveryErrorBoundary");

    const ErrorFallback = ({ error, resetErrorBoundary }) => {
        useEffectOnce(() => {
            if (errorInfo && errorInfo.timeOfError + 500 > new Date().getTime()) {
                // error loop, recovery failed, go to the global error handler
                setFailedRecovery(true);
            } else {
                setErrorInfo({ error, timeOfError: new Date().getTime() });
                try {
                    dispatch(
                        notificationActions.addGenericNotification({
                            heading: t("RecoveryErrorBoundary:title"),
                            message: t("RecoveryErrorBoundary:message"),
                            notificationType: "generic-error"
                        })
                    );
                } catch (e) {
                    // do nothing
                }
                // trying to recover, this will activate onReset hook which will trigger resetLogic function and redraw the component
                resetErrorBoundary();
            }
        });
        return (
            <div className="recovery-error-boundary">
                <p>{t("RecoveryErrorBoundary:bad_exception")}</p>
            </div>
        );
    };

    const FailedRecoveryError = () => {
        // so the global handler handles it
        throw errorInfo.error;
    };

    return (
        <>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                    // try to reset the state of your app so the error doesn't happen again
                    props.resetLogic();
                }}
                onError={(error, info) => logBoundaryError(error, info, props.boundaryName)}
            >
                {props.children}
            </ErrorBoundary>
            {failedRecovery && <FailedRecoveryError />}
        </>
    );
};

export default RecoveryErrorBoundary;
