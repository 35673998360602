import React, { HTMLAttributes } from "react";
import {
    bem,
    ButtonContainer,
    Empty,
    IcInfo16,
    IconSelect,
    IcUpload16,
    QaIdModifier,
    Tooltip,
    useQaId
} from "../../ui-library";
import { useTranslation } from "react-i18next";

import IsomorphicSuspense from "../../classes/IsomorphicSuspense";

import "./SendDestination.scss";

const sendDestinationBem = bem("send-destination");

type SendDestinationProps = QaIdModifier<HTMLAttributes<HTMLDivElement>> & {
    isActiveDestination?: boolean;
    onSendToDevice?: () => void;
    sendStops?: boolean;
    tooltip?: boolean;
};

const SendDestinationCmp: React.FC = ({
    isActiveDestination,
    onSendToDevice,
    sendStops,
    tooltip,
    qaIdModifier
}: SendDestinationProps) => {
    const sendDestinationQa = useQaId("send-destination", null, qaIdModifier);
    const { t } = useTranslation("SendDestination");
    const destinationText = isActiveDestination
        ? "SendDestination:remove_destination"
        : "SendDestination:send_destination";
    return (
        <div className={sendDestinationBem.block()} {...sendDestinationQa.block()}>
            <ButtonContainer onClick={onSendToDevice} {...sendDestinationQa.element("action")}>
                <IconSelect
                    type="secondary"
                    icon={<IcUpload16 />}
                    value={t(sendStops ? "SendDestination:send_stops" : destinationText)}
                    state={"default"}
                    parentQaId={sendDestinationQa.qaId()}
                />
            </ButtonContainer>
            {tooltip && (
                <Tooltip
                    message={t(
                        sendStops ? "SendDestination:send_stops_tooltip" : "SendDestination:send_destination_tooltip"
                    )}
                    {...sendDestinationQa.element("tooltip")}
                >
                    <div
                        className={sendDestinationBem.element("tooltip-trigger")}
                        {...sendDestinationQa.element("tooltip-trigger")}
                    >
                        <IcInfo16 />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

const SendDestination = (props: SendDestinationProps) => (
    <IsomorphicSuspense fallback={<Empty />}>
        <SendDestinationCmp {...props} />
    </IsomorphicSuspense>
);

export default SendDestination;
